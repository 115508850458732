import React, { useContext, useState } from 'react';
import EditTemplateModal from './EditTemplateModal';
import AppContext from '../../../Context/AppContext';

function TemplateCard({ templateDetails, setShowAddModal, success, setSuccess }) {
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
  const { setTemplateData } = useContext(AppContext);
  function getTotalTemplateTasks(template) {
    let totalTasks = 0;
    template.TemplateGroups.forEach((group) => {
      totalTasks += group.TemplateTasks.length;
    });
    return totalTasks;
  }

  const totalTemplateTasks = getTotalTemplateTasks(templateDetails);

  function convertToDataObject(templateGroups = []) {
    const field = [];
    templateGroups.forEach((group) => {
      group.TemplateTasks.forEach((task) => {
        field.push({
          taskName: task.name,
          group: {
            label: group.name,
            value: group.name,
          },
        });
      });
    });
    return { field };
  }

  const handleEditTemplateModal = () => {
    const templateObj = {
      id: templateDetails.id,
      name: templateDetails.name,
      description: templateDetails.description,
      groups: templateDetails?.TemplateGroups.map((group) => ({ label: group.name, value: group.name })) ?? [],
    };
    const dataObject = convertToDataObject(templateDetails?.TemplateGroups);
    setTemplateData({ ...templateObj, ...dataObject });
    setShowEditTemplateModal(true);
  };

  const closeTemplateEditModal = () => {
    setTemplateData({ name: '', description: '', groups: [] });
    setShowEditTemplateModal(false);
  };

  return (
    <>
      <div className="col-sm-6 col-12 col-xl-4 col-md-4 mb-5 mb-xl-10">
        <div className="card rounded border-primary border border-dashed">
          <div className="card-body d-flex justify-content-between align-items-start flex-column">
            <div className="d-flex edit-icon" onClick={handleEditTemplateModal}>
              <div role="button">
                <i className="fa fa-edit text-primary"></i>
              </div>
            </div>
            <div className="d-flex flex-column my-4" style={{ overflow: 'hidden', width: '90%' }}>
              <span className="fw-semibold fs-2x pb-1 lh-1 ls-n2" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {templateDetails?.name ?? ''}
              </span>
              <div className="m-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <span
                  className="fw-semibold fs-6 text-gray-500"
                  style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                  {templateDetails?.description ?? ''}
                </span>
              </div>
            </div>
            <p className="h4 mb-5">
              Groups: <span className="text-primary">{templateDetails?.TemplateGroups?.length ?? 0}</span> | Tasks:
              <span className="text-primary">{totalTemplateTasks}</span>
            </p>
            <div
              role="button"
              className="btn btn-sm btn-outline-primary flex-shrink-0"
              onClick={() =>
                setShowAddModal({ show: true, selectedTemplate: { label: templateDetails?.name ?? '', value: templateDetails?.id ?? '' } })
              }
            >
              Create Product
            </div>
          </div>
        </div>
      </div>

      {showEditTemplateModal && (
        <EditTemplateModal
          show={showEditTemplateModal}
          onHide={closeTemplateEditModal}
          success={success}
          setSuccess={setSuccess}
          setShowAddModal={setShowAddModal}
        />
      )}
    </>
  );
}

export default TemplateCard;
