import React, { useState, useContext, useEffect } from 'react';
import ProductCards from '../Products/Cards';
import { Link } from 'react-router-dom';
import AddProductModal from '../Products/AddProductModal';
import AppContext from '../../../Context/AppContext';
import { getProductCardsDetails } from '../../../services/sujata/products';
import TemplateCard from '../Templates/Card';
import AddTemplateModal from '../Templates/AddTemplateModal';
import { getAllTemplates } from '../../../services/sujata/templates';

function Dashboard() {
  const [showAddModal, setShowAddModal] = useState({ show: false });
  const [success, setSuccess] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [productCards, setProductCards] = useState([]);
  const { masterData, allTemplateData, setAllTemplateData, setTemplateData } = useContext(AppContext);

  useEffect(() => {
    getAllTemplates()
      .then((response) => {
        if (response.success) {
          setAllTemplateData(response.data.reverse());
        }
      })
      .catch((error) => {
        window.toastr.error(error);
      });
  }, [setAllTemplateData, success]);

  const fetchProductDetails = async () => {
    try {
      const response = await getProductCardsDetails();

      if (response.success) {
        if (response?.data && Object.keys(response?.data).length > 0) {
          const productDetails = [];
          Object.keys(response.data).forEach((key) => {
            productDetails.unshift(response.data[key]);
          });
          setProductCards(productDetails);
        }
      }
    } catch (error) {
      window.toastr.error(error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [success]);

  const onHide = () => {
    setShowAddModal({ show: false });
  };

  const onHideTemplateAddModal = () => {
    setTemplateData({ name: '', description: '', groups: [] });
    setShowAddTemplateModal(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start flex-wrap mb-0 mt-5">
        <div className="fs-2 fw-bold">
          <h2 className="mb-0">Home</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home
              </Link>
            </li>
          </ul>
        </div>

        <div className="card-toolbar d-flex top-buttons" id="btnContainer" style={{ zIndex: 'auto' }}>
          <div className="grid-list">
            <Link to="/product-list" type="button" className="btn btn-sm">
              View all
            </Link>
          </div>
        </div>
      </div>

      {/* Products  */}
      <div className="row mt-5 pt-0 mb-5 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="row gy-5 g-xl-10">
            <div className="col-xl-4">
              <div className="card py-5 border-0 h-100" dir="ltr" style={{ backgroundColor: '#fff2f2' }}>
                <div className="card-body d-flex flex-column flex-center">
                  <div className="mb-2">
                    <h3 className="fw-semibold text-gray-800 text-center lh-lg">
                      Manage your
                      <br />
                      product with
                      <span className="fw-bolder"> ease & transparency</span>
                    </h3>
                    <div className="py-10 text-center">
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/2.svg`} className="theme-light-show w-200px" alt="" />
                      <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/2-dark.svg`} className="theme-dark-show w-200px" alt="" />
                    </div>
                  </div>
                  <div className="text-center mb-1">
                    <div role="button" className="btn btn-primary" onClick={() => setShowAddModal({ show: true })}>
                      Create New Product
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {productCards.length > 0 && productCards.map((product) => <ProductCards key={product.id} productDetail={product} />)}
          </div>
        </div>
      </div>

      {/* Templates */}

      <div className="d-flex justify-content-between align-items-start flex-wrap mb-0 mt-5 pt-5">
        <div className="fs-2 fw-bold">
          <h2 className="mb-0">Templates</h2>
          <div className="fs-6 fw-semibold text-muted">
            Templates enable you to define once and re-use the product structure including groups and tasks. Save time and effort in handling
            repetitive actions.
          </div>
        </div>

        <div className="card-toolbar d-flex top-buttons" id="btnContainer">
          <button className="btn btn-sm btn-secondary flex-shrink-0" onClick={() => setShowAddTemplateModal(true)}>
            <i className="fa fa-plus"></i> Template
          </button>
        </div>
      </div>

      <div className="row gy-5 g-xl-6 mt-0 project-templates">
        <div className="col-lg-9 col-md-12 col-sm-12 col-12">
          <div className="row">
            {Array.isArray(allTemplateData) &&
              allTemplateData.length > 0 &&
              allTemplateData.map((template) => (
                <TemplateCard
                  key={template.id}
                  templateDetails={template}
                  setShowAddModal={setShowAddModal}
                  success={success}
                  setSuccess={setSuccess}
                />
              ))}
          </div>
        </div>
      </div>

      {showAddModal.show && (
        <AddProductModal
          show={showAddModal.show}
          onHide={onHide}
          masterData={masterData}
          success={success}
          setSuccess={setSuccess}
          selectedTemplate={showAddModal?.selectedTemplate ?? ''}
        />
      )}
      {showAddTemplateModal && (
        <AddTemplateModal
          show={showAddTemplateModal}
          onHide={onHideTemplateAddModal}
          setShowAddModal={setShowAddModal}
          success={success}
          setSuccess={setSuccess}
        />
      )}
    </>
  );
}

export default Dashboard;
