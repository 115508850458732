import { axios } from '../../utils/axios';
const baseUrl = process.env.REACT_APP_API_HOST;

export const filterWithGetAllTimesheet = async (postData) => {
  let jsonData;
  try {
    let res = await axios.post(`${baseUrl}/timesheet`, postData);
    jsonData = res;
  } catch (e) {
    if (e?.response) {
      jsonData = e.response.data;
      window.toastr.error(jsonData.message);
    } else {
      jsonData = {};
    }
  }
  return jsonData;
};

export const getReports = async (payload, params, exportFile = false) => {
  let jsonData;
  try {
    let res = await axios.post(`${baseUrl}/timesheet/export`,payload, {params: params});
  jsonData = res?.data;
  if(!res?.success) {
    window.toastr.error(res?.message);
  }
} catch (e) {
  jsonData = {};
}
return jsonData;
}