import moment from "moment";

// let strRegex = new RegExp(/^[a-z0-9]+$/i);
// let strRegexPirmaryDiagnosis = new RegExp(/^[a-z0-9.]+$/i);
const TaskFormValidator = (values, product) => {
  let errors = {};

  if (values.hasOwnProperty('name')) {
    if (values?.name?.length < 3) {
      errors.name = "Name can not be less than 3 characters"
    }
    if (values?.name?.length > 100) {
      errors.name = "Name can not be greater than 100 characters"
    }
  }
  if (values.hasOwnProperty('start_date')) {
    if (values?.start_date === '' || values?.start_date === null) {
      errors.start_date = 'Required';
    }

    const productStartDate = moment(product?.start_date, 'DD-MM-YYYY');
    const valuesStartDate = moment(values?.start_date, 'DD-MM-YYYY');
    const productEndDate = moment(product?.end_date, 'DD-MM-YYYY');

    if (valuesStartDate.isBefore(productStartDate) || valuesStartDate.isAfter(productEndDate)) {
      errors.start_date = 'Start Date must be between Product start and end dates';
    }
  }
  if (values.hasOwnProperty('assignee_id')) {
    if (!Array.isArray(values?.assignee_id)) {
      errors.assignee_id = 'Wrong type';
    }
  }
  if (values.hasOwnProperty('end_date')) {
    if (values?.end_date === '' || values?.end_date === null) {
      errors.end_date = 'Required';
    }

    const productStartDate = moment(product?.start_date, 'DD-MM-YYYY');
    const valuesEndDate = moment(values?.end_date, 'DD-MM-YYYY');
    const productEndDate = moment(product?.end_date, 'DD-MM-YYYY');
  
    if (moment(valuesEndDate).isBefore(productStartDate) || moment(valuesEndDate).isAfter(productEndDate)) {
      errors.end_date = 'End Date must be between Product start and end dates';
    }
  }
  if (values.hasOwnProperty('MilestoneId')) {
    if (values?.MilestoneId === '' || values?.MilestoneId === null) {
      errors.description = 'Required';
    }
  }
  if (values.hasOwnProperty('Priority')) {
    if (values?.MilestoneId === '' || values?.MilestoneId === null) {
      errors.description = 'Required';
    }
  }

  return errors;
};
export default TaskFormValidator;
