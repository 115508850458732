import React, { useState } from 'react';
import WizardSteps from './WizardSteps';
import WizardForm from './WizardForm';

function TemplateWizard({ wizardType = 'add', hideTemplateModal, setShowAddProductModal, success, setSuccess }) {
  const [activeStep, setActiveStep] = useState({ value: 'info', index: 0 });

  const steps = [
    { label: 'Info', sub_label: 'Identify your template', value: 'info' },
    { label: 'Groups', sub_label: 'Organize your tasks', value: 'groups' },
    { label: 'Tasks', sub_label: 'Pre-define actions', value: 'tasks' },
    { label: 'Done', sub_label: `Template ${wizardType === 'add' ? 'created' : 'edited'}`, value: 'done' },
  ];

  return (
    <>
      <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
        <div className="stepper-nav ps-lg-10">
          <WizardSteps steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
        </div>
      </div>
      <div className="flex-row-fluid py-lg-5 px-lg-15">
        <WizardForm
          wizardType={wizardType}
          activeStep={activeStep.value}
          setActiveStep={setActiveStep}
          setShowAddProductModal={setShowAddProductModal}
          hideTemplateModal={hideTemplateModal}
          success={success}
          setSuccess={setSuccess}
        />
      </div>
    </>
  );
}

export default TemplateWizard;
