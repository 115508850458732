import { axios } from "../../utils/axios";
const baseUrl = process.env.REACT_APP_API_HOST;

export const addTemplate = async (data) => {
  let jsonData;
  try {
    let res = await axios.post(`${baseUrl}/templates`, data);
    jsonData = res;
  } catch (e) {
    if (e?.response) {
      jsonData = e.response.data;
    } else {
      jsonData = {};
    }
  }
  return jsonData;
};

export const editTemplate = async (template_id, data) => {
  let jsonData;
  try {
    let res = await axios.put(`${baseUrl}/templates/${template_id}`, data);
    jsonData = res;
  } catch (e) {
    if (e?.response) {
      jsonData = e.response.data;
    } else {
      jsonData = {};
    }
  }
  return jsonData;
};

export const getAllTemplates = () => {
  try {
    return fetch(`${baseUrl}/templates`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log(error);
  }
};