import { Modal } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import { useState } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { addLogHours, updateLogHours } from '../../../services/sujata/tasks';
import { DURATION_GLOBAL } from '../../../utils/constants';

function LogHoursModal({ showModalBox, setShowModalBox, setSuccess, success, handleModalClose, modalValues }) {
  const [hours, setHours] = useState(modalValues.hours);
  const [logDate, setLogDate] = useState(modalValues.date);
  const [logDescription, setLogDescription] = useState(modalValues.description);
  const [errors, setErrors] = useState({ isValid: true });

  const handleLogHoursSave = (e) => {
    e.preventDefault();
    const isFormValid = validateInput([hours, logDate, logDescription]);
    if (modalValues.modal_type === 'add') {
      const button = document.getElementById('savebutton');
      button.disabled = true;
      if (isFormValid) {
        addLogHours(modalValues.task_id, { hours: hours, date: logDate, description: logDescription })
          .then((res) => {
            button.disabled = false;
            if (res?.success === true) {
              window.toastr.success(res?.message);
              setSuccess(!success);
              setShowModalBox(false);
            } else {
              window.toastr.error(res?.message ?? 'Something went wrong');
            }
          })
          .catch((err) => {
            button.disabled = false;
            window.toastr.error(err?.message ?? 'Something went wrong');
          });
      } else {
        button.disabled = false;
      }
    }
    if (modalValues.modal_type === 'update') {
      const button = document.getElementById('savebutton');
      button.disabled = true;
      if (isFormValid) {
        updateLogHours(modalValues.task_id, modalValues.id, {
          hours: hours,
          date: moment(logDate, 'DD-MM-YYYY').format('DD-MM-YYYY'),
          description: logDescription,
        })
          .then((res) => {
            button.disabled = false;
            if (res?.success === true) {
              window.toastr.success(res?.message);
              setSuccess(!success);
              setShowModalBox(false);
            } else {
              window.toastr.error(res?.message ?? 'Something went wrong');
            }
          })
          .catch((err) => {
            button.disabled = false;
            window.toastr.error(err?.message ?? 'Something went wrong');
          });
      } else {
        button.disabled = false;
      }
    }
  };

  const validateInput = (inputs) => {
    const [hours, logDate, logDescription] = inputs;
    if (!DURATION_GLOBAL.test(hours)) {
      setErrors({
        isValid: false,
        type: 'hours',
        msg: 'Empty or Invalid format',
      });
      return false;
    }
    if (logDate === '' || logDate === null || logDate === undefined) {
      setErrors({
        isValid: false,
        type: 'date',
        msg: 'Empty or Invalid format',
      });
      return false;
    }
    if (logDescription === null || logDescription === undefined || logDescription === '' || logDescription.trim() === '') {
      setErrors({
        isValid: false,
        type: 'description',
        msg: 'Please fill this field.',
      });
      return false;
    } else if (logDescription.length > 500) {
      setErrors({
        isValid: false,
        type: 'description',
        msg: 'Description should not exceed 500 characters.',
      });
      return false;
    }

    setErrors({
      isValid: true,
    });
    return true;
  };

  return (
    <Modal show={showModalBox} centered>
      <Modal.Body className="modal-body scroll-y mb-5">
        <div id="location">
          <div className="applicant-fileds">
            <div className="form-group">
              <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-2">
                  <label className="form-label mb-2 fw-bold">Log hours for this task</label>
                  <div className="card-body p-2">
                    <div className="card-body d-flex flex-column pb-0">
                      <div className="d-flex flex-column gap-5 gap-md-7">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-6">
                            <label className="form-label">Task #</label>
                            <input className="form-control" name="task_no" defaultValue={modalValues?.task_no} disabled autoComplete="off" />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-6">
                            <label className="form-label">Assignee</label>
                            <input
                              className="form-control"
                              autoComplete="off"
                              defaultValue={modalValues?.assignee_name}
                              name="assignee_name"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-6">
                            <OverlayTrigger placement={'top'} overlay={<Tooltip> Formats supported: 02:30, 2h30m, 2h, 30m, 2.5 </Tooltip>}>
                              <label className="required form-label">
                                Log Hours <i className="fas fa-exclamation-circle ms-1 fs-6"></i>
                              </label>
                            </OverlayTrigger>
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              defaultValue={hours ?? '01:00'}
                              onChange={(e) => {
                                e.preventDefault();
                                setHours(e.target.value);
                              }}
                              name="log_hours"
                              placeholder="HH:MM"
                              disabled={modalValues.modal_type === 'view' ? true : false}
                            />
                            {!errors?.isValid && errors?.type === 'hours' && <p className="help is-danger errorDanger">{errors?.msg}</p>}
                          </div>
                          <div className="col-xl-6 col-lg-6 col-6">
                            <label className="required form-label">Log Date</label>
                            <Flatpickr
                              className="form-control"
                              options={{ dateFormat: 'd-m-Y', animate: true, maxDate: moment().format('DD-MM-YYYY') }}
                              value={logDate}
                              name="start_date"
                              onChange={(e) => {
                                setLogDate(moment(e[0]).format('DD-MM-YYYY'));
                              }}
                              disabled={modalValues.modal_type === 'view' ? true : false}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-12">
                            <label className="required form-label">Log Description</label>
                            <textarea
                              required
                              autoComplete="off"
                              className="form-control"
                              name="log_description"
                              defaultValue={logDescription}
                              onChange={(e) => {
                                e.preventDefault();
                                setLogDescription(e.target.value);
                              }}
                              disabled={modalValues.modal_type === 'view' ? true : false}
                              placeholder="Type your description here..."
                            />
                            {!errors?.isValid && errors?.type === 'description' && <p className="help is-danger errorDanger">{errors?.msg}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pt-8 mt-10">
          <button
            type="reset"
            className="btn btn-light me-3 btn-active-light-primary"
            onClick={modalValues.modal_type === 'view' ? () => setShowModalBox(false) : handleModalClose}
          >
            {modalValues.modal_type === 'view' ? 'Close' : 'Cancel'}
          </button>
          {modalValues.modal_type !== 'view' ? (
            <button
              className="btn btn-sm btn-secondary me-4"
              id="savebutton"
              type="button"
              onClick={modalValues.modal_type === 'view' ? null : handleLogHoursSave}
              disabled={modalValues.modal_type === 'view' ? true : false}
            >
              <span>Save</span>
            </button>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LogHoursModal;
