import React, { useState, useContext, useEffect } from 'react';
import AddProductModal from '../AddProductModal';
import { Link } from 'react-router-dom';
import AppContext from '../../../../Context/AppContext';
import { getMasterData, productListFilter } from '../../../../services/sujata/products';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getAllTemplates } from '../../../../services/sujata/templates';
import moment from 'moment';
import CustomPagination from '../../../Manager/ManagerWorkLists/CustomPagination';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function ProductList() {
  const [showAddModal, setShowAddModal] = useState({ show: false });
  const { masterData, setMasterData, allTemplateData, setAllTemplateData } = useContext(AppContext);
  const [productList, setProductList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, totalRecords: 1 });
  const [resetFlag, setResetFlag] = useState(false);
  const [key, setKey] = useState(0);

  const onHide = () => {
    setShowAddModal({ show: false });
  };

  const {
    // register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      product_name: '',
      product_type: '',
      duration: '',
      start_date: '',
      end_date: '',
      product_status: '',
      product_owner: '',
      users: [],
    },
  });

  const handleResetForm = () => {
    reset({
      product_name: '',
      product_type: '',
      duration: '',
      start_date: '',
      end_date: '',
      product_status: '',
      product_owner: '',
      users: [],
    });
    setResetFlag(!resetFlag);
    setKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    getMasterData()
      .then((response) => {
        if (response.success) {
          setMasterData(response.data);
        }
      })
      .catch((error) => {
        window.toastr.error(error);
      });
    if (!allTemplateData) {
      getAllTemplates()
        .then((response) => {
          if (response.success) {
            setAllTemplateData(response.data.reverse());
          }
        })
        .catch((error) => {
          window.toastr.error(error);
        });
    }
  }, [allTemplateData, setAllTemplateData, setMasterData, resetFlag]);

  useEffect(() => {
    productListFilter(pagination.page, {}).then((response) => {
      if (response?.success) {
        const { data } = response;
        const productsArray = [];

        if (Object.keys(data).length === 1) {
          setProductList(productsArray);
        }
        Object.keys(data).forEach((key, index) => {
          if (key !== '0' && key !== '1' && key !== '2' && key !== '3' && key !== '4') return;
          if (data[key].start_date) {
            data[key].start_date = moment(data[key].start_date).format('DD MMM YY');
          }
          if (data[key].end_date) {
            data[key].end_date = moment(data[key].end_date).format('DD MMM YY');
          }
          productsArray.push(data[key]);
        });
        setProductList(productsArray);
        setPagination({ ...pagination, totalRecords: response?.data?.totalRecords });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFlag]);

  const renderAllUsers = () => {
    return masterData?.users_all?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const renderAllStatus = () => {
    return masterData?.product_statuses?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const renderProductTypes = () => {
    return masterData?.product_types?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const renderProductNames = () => {
    return masterData?.product_names?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const handleFormSubmit = handleSubmit(async (data) => {
    const button = document.getElementById('filterButtonProductList');
    button.disabled = true;
    const selectedProductMember = data?.users?.map((option) => option?.value) ?? [];
    const selectedProductOwner = data?.product_owner?.value;
    data = {
      ...data,
      product_member: selectedProductMember,
      product_owner: selectedProductOwner,
      duration: parseInt(data?.duration),
    };
    const reqBody = {
      product_name: data?.product_name?.label,
      TypeId: data?.product_type?.value,
      StatusId: data?.product_status?.value,
      start_date: data?.start_date,
      end_date: data?.end_date,
      duration: data?.duration,
      owner_id: data?.product_owner,
      projectMembersId: data?.product_member,
    };
    const response = await productListFilter(pagination.page, reqBody);
    button.disabled = false;
    if (response?.success) {
      const { data } = response;
      const productsArray = [];
      if (Object.keys(data).length === 1) {
        setProductList(productsArray);
      }
      Object.keys(data).forEach((key, index) => {
        if (key !== '0' && key !== '1' && key !== '2' && key !== '3' && key !== '4') return;
        if (data[key].start_date) {
          data[key].start_date = moment(data[key].start_date).format('DD MMM YY');
        }
        if (data[key].end_date) {
          data[key].end_date = moment(data[key].end_date).format('DD MMM YY');
        }
        productsArray.push(data[key]);
      });
      setProductList(productsArray);
      setPagination({ ...pagination, totalRecords: response?.data?.totalRecords });
    }
    // Handle form submission here
  });

  const handlePaginationChange = async (pageNumber) => {
    const formValues = getValues() ?? {};
    const selectedProductMember = formValues?.users?.map((option) => option?.value) ?? [];
    const selectedProductOwner = formValues?.product_owner?.value;
    const data = {
      ...formValues,
      product_member: selectedProductMember,
      product_owner: selectedProductOwner,
      duration: parseInt(formValues?.duration),
    };
    const reqBody = {
      product_name: data?.product_name,
      TypeId: data?.product_type,
      StatusId: data?.product_status.value,
      start_date: data?.start_date,
      end_date: data?.end_date,
      duration: data?.duration,
      owner_id: data?.product_owner,
      projectMembersId: data?.product_member,
    };
    const response = await productListFilter(pageNumber, reqBody);
    if (response?.success) {
      const { data } = response;
      const productsArray = [];
      if (Object.keys(data).length === 1) {
        setProductList(productsArray);
      }
      Object.keys(data).forEach((key, index) => {
        if (key !== '0' && key !== '1' && key !== '2' && key !== '3' && key !== '4') return;
        if (data[key].start_date) {
          data[key].start_date = moment(data[key].start_date).format('DD MMM YY');
        }
        if (data[key].end_date) {
          data[key].end_date = moment(data[key].end_date).format('DD MMM YY');
        }
        productsArray.push(data[key]);
      });
      setProductList(productsArray);
      setPagination({ totalRecords: response?.data?.totalRecords, page: pageNumber });
    }
  };

  const handleEventDate = (event, picker) => {
    picker.element.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    setValue('start_date', picker.startDate.format('DD/MM/YYYY'));
    setValue('end_date', picker.endDate.format('DD/MM/YYYY'));
  };

  const handleCancelDate = (event, picker) => {
    picker.element.val('');
    setKey((prevKey) => prevKey + 1);
    setValue('start_date', '');
    setValue('end_date', '');
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start flex-wrap mt-4">
        <div className="fs-2 fw-bold">
          <h2>Products</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home{' '}
              </Link>
            </li>

            <li className="breadcrumb-item text-gray-900">Products</li>
          </ul>
        </div>

        <div className="card-toolbar d-flex top-buttons" id="btnContainer" style={{ zIndex: 'auto' }}>
          <button type="button" className="btn btn-primary btn-sm ms-2" onClick={() => setShowAddModal({ show: true })}>
            <i className="fa fa-plus"></i>Add
          </button>
        </div>
      </div>
      <div className="row mt-5 pt-0">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="col-xl-12">
            <div className="card">
              {/* Filter Inputs */}
              <div className="card-header d-block pt-3">
                <form onSubmit={handleFormSubmit}>
                  <h5 className="card-title mb-3">Search Filter</h5>
                  <div className="row pb-2 gap-3 gap-md-0">
                    <div className="col-md-1">
                      <Controller
                        name="product_name"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            placeholder="Product"
                            isClearable
                            options={renderProductNames()}
                          />
                        )}
                      />
                    </div>

                    <div className="col-md-1">
                      <Controller
                        name="product_type"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Type"
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            isClearable
                            options={renderProductTypes()}
                          />
                        )}
                      />
                    </div>

                    <div className="col-md-1">
                      <Controller
                        name="duration"
                        control={control}
                        render={({ field }) => <input {...field} min={0} max={100} type="number" className="form-control" placeholder="Duration" />}
                      />
                      {errors.duration && <span style={{ color: 'red' }}>Please enter a valid duration (1 - 999)</span>}
                    </div>

                    <div className="col-md-2">
                      <Controller
                        name="product_owner"
                        control={control}
                        render={({ field }) => (
                          <Select
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            {...field}
                            placeholder="Owner"
                            isClearable
                            options={renderAllUsers()}
                          />
                        )}
                      />
                    </div>

                    <div className="col-md-2">
                      <Controller
                        name="users"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            placeholder="Users"
                            isMulti
                            isClearable={false}
                            options={renderAllUsers()}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-2">
                      <DateRangePicker
                        key={key}
                        initialSettings={{
                          autoUpdateInput: false,
                          locale: { cancelLabel: 'Clear', format: 'DD/MM/YYYY' },
                          showDropdowns: true,
                        }}
                        onApply={handleEventDate}
                        style={{ zIndex: '2000' }}
                        onCancel={handleCancelDate}
                      >
                        <input
                          type="text"
                          className="form-control flatpickr-input"
                          placeholder="Pick date range"
                          id="kt_daterangepicker_2"
                          title="Select Date Range"
                        />
                      </DateRangePicker>
                    </div>
                    <div className="col-md-1">
                      <Controller
                        name="product_status" // Active / Inactive
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            placeholder="Status"
                            isClearable
                            options={renderAllStatus()}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-2">
                      <button type="submit" id="filterButtonProductList" className="btn ntn-sm btn-secondary me-sm-3 me-1">
                        Filter
                      </button>
                      <button type="reset" className="btn btn-label-secondary" onClick={handleResetForm}>
                        Clear
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {/* Filter Table */}
              <div className="card-body py-4">
                <div className="table-responsive">
                  <table className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-7" id="kt_ecommerce_sales_table">
                    <thead>
                      <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-40px">Name</th>
                        <th className="min-w-50px">Type</th>
                        <th className="min-w-40px">Duration</th>
                        <th className="min-w-80px">Owner</th>
                        <th className="min-w-150px">Members</th>
                        <th className="min-w-40px">Start Date</th>
                        <th className="min-w-40px">End Date</th>
                        <th className="min-w-40px">Status</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 fw-semibold">
                      {productList.length > 0 ? (
                        productList.map((product) => (
                          <tr key={product?.id}>
                            <td>
                              <Link to={`/products/${product.id}`}>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 300 }}
                                  overlay={<Tooltip id="button-tooltip">{product?.name}</Tooltip>}
                                >
                                  <div style={{ width: '150px' }} className="text-gray-800 text-hover-primary fw-bold text-truncate">
                                    <strong className="text-gray-900">{product.name ?? ''}</strong>
                                  </div>
                                </OverlayTrigger>
                              </Link>
                            </td>
                            <td>
                              <Link to={`/products/${product.id}`}>{product.WorklistTypeName ?? ''}</Link>
                            </td>
                            <td>
                              <Link to={`/products/${product.id}`}>{product.duration} Months</Link>
                            </td>
                            <td>
                              <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title={`${product?.owner?.full_name ?? ''}`}>
                                {product?.owner?.image_url ? (
                                  <img alt="Pic" src={product?.owner?.image_url} />
                                ) : (
                                  <span className="symbol-label bg-danger text-inverse-danger fw-bold">{product?.owner?.full_name?.[0] ?? ''}</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="symbol-group symbol-hover mb-1">
                                {product?.productMembers?.map((member) => (
                                  <div
                                    key={member?.id}
                                    className="symbol symbol-25px symbol-circle"
                                    data-bs-toggle="tooltip"
                                    title={`${member.first_name ?? ''} ${member?.last_name ?? ''}`}
                                  >
                                    {member?.image_url ? (
                                      <img alt="Pic" src={member?.image_url} />
                                    ) : (
                                      <span className="symbol-label bg-danger text-inverse-danger fw-bold">{member?.first_name?.[0] ?? ''}</span>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td>{product?.start_date}</td>
                            <td>{product?.end_date}</td>
                            <td>
                              <div
                                className={`badge fw-bold ${
                                  product?.WorklistStatusName === 'Inactive' ? 'badge-light-danger' : 'badge-light-success'
                                }`}
                              >
                                {product?.WorklistStatusName ?? ''}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <div>
                            <p>No records found...</p>
                          </div>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {pagination.totalRecords > 0 && (
                    <CustomPagination
                      dataPerPage={5}
                      totalData={pagination.totalRecords}
                      paginate={handlePaginationChange}
                      currentPage={pagination.page}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddModal.show && <AddProductModal show={showAddModal.show} onHide={onHide} success={resetFlag} setSuccess={setResetFlag} />}
    </>
  );
}

export default ProductList;
