import React, { useState } from 'react';
import AppContext from './AppContext';
const AppContextProvider = ({ children }) => {
  const [masterData, setMasterData] = useState([]);
  const [templateData, setTemplateData] = useState({ name: '', description: '', groups: [] });
  const [allTemplateData, setAllTemplateData] = useState();

  return (
    <AppContext.Provider
      value={{
        masterData,
        setMasterData,
        templateData,
        setTemplateData,
        allTemplateData,
        setAllTemplateData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppContextProvider;
