import React, { useContext } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Select from 'react-select';
import { addTemplate, editTemplate } from '../../../../../services/sujata/templates';
import AppContext from '../../../../../Context/AppContext';

function Tasks({ prevStep, nextStep, setActiveStep, success, setSuccess, wizardType }) {
  const { templateData } = useContext(AppContext);
  const { groups } = templateData;

  const handleNextClick = () => {
    setActiveStep(nextStep);
  };

  let defaultFormState;
  if (wizardType === 'edit') {
    const groupLabels = groups.map((group) => group.label);
    const fields = templateData.field.filter((field) => groupLabels.includes(field.group.label));
    defaultFormState = {
      defaultValues: {
        field: fields,
      },
    };
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm(defaultFormState);

  const { fields, append } = useFieldArray({
    name: 'field',
    control,
  });

  const handlePreviousClick = (e) => {
    e.preventDefault();
    setActiveStep(prevStep);
  };

  const restructureData = (data) => {
    const details = {};

    // Initialize details object with empty arrays for each group
    templateData.groups.forEach((group) => {
      details[group.label] = [];
    });

    // Populate details with tasks from the data
    data.field.forEach((item) => {
      const groupName = item.group.label;
      const taskName = item.taskName;
      details[groupName].push(taskName);
    });

    // Convert details object to the desired api request format
    return Object.keys(details).map((groupName) => ({ [groupName]: details[groupName] }));
  };

  const handleFormSubmit = handleSubmit((data) => {
    const button = document.getElementById('submitButton');
    button.disabled = true;
    if (data?.field?.length === 0) {
      window.toastr.error('Template should have at least 1 Group and 1 Task');
      return;
    }

    const details = restructureData(data);

    const apiRequest = {
      name: templateData.name.trim(),
      description: templateData.description.trim(),
      details,
    };

    if (wizardType === 'add') {
      addTemplate(apiRequest)
        .then((response) => {
          button.disabled = false;
          if (response.success) {
            setSuccess(!success);
            handleNextClick();
          } else {
            if (response?.error?.errors && Array.isArray(response.error.errors)) {
              if (response.error.errors[0]?.message === 'name must be unique') {
                window.toastr.error('Template already exists with that name. Please enter a new one.');
              } else {
                window.toastr.error('Template could not be saved. Please check the inputs.');
              }
            } else {
              window.toastr.error('Template could not be saved. Please try again.');
            }
          }
        })
        .catch((err) => {
          button.disabled = false;
          window.toastr.error(err);
        });
    }
    if (wizardType === 'edit') {
      editTemplate(templateData.id, apiRequest)
        .then((response) => {
          button.disabled = false;
          if (response.success) {
            setSuccess(!success);
            handleNextClick();
          } else {
            if (response?.error?.errors && Array.isArray(response.error.errors)) {
              if (response.error.errors[0]?.message === 'name must be unique') {
                window.toastr.error('Template already exists with that name. Please enter a new one.');
              } else {
                window.toastr.error('Template could not be saved. Please check the inputs.');
              }
            } else {
              window.toastr.error('Template could not be saved. Please try again.');
            }
          }
        })
        .catch((err) => {
          button.disabled = false;
          window.toastr.error(err);
        });
    }
  });

  const renderGroups = () => {
    return groups?.map(({ label, value }) => ({
      label,
      value,
    }));
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="current">
        <div className="w-100">
          <div className="row mb-10">
            <div className="col-md-12 fv-row">
              <div className="d-flex align-items-end justify-content-between fs-5 fw-semibold mb-4">
                <span>{fields.length > 0 && <label className="required">Task Name</label>}</span>
                <button
                  type="button"
                  id="templateAddButton"
                  disabled={groups.length === 0}
                  className="btn btn-sm btn-outline-primary flex-shrink-0"
                  onClick={() => {
                    append({ taskName: '', group: '' });
                  }}
                  title="Click to add Tasks"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
              {fields.map((field, index) => (
                <div key={field.id} className="row fv-row">
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter task name"
                      {...register(`field[${index}].taskName`, {
                        validate: (value) => {
                          if (value.trim() === '') {
                            return 'Required';
                          }
                          if (value.trim().length < 3) {
                            return "Input can't be less than 3 characters";
                          }
                          if (value.trim().length > 100) {
                            return "Input can't be more then 100 characters";
                          }
                          return true;
                        },
                      })}
                    />
                    <small className="text-danger">{errors?.field?.[index]?.taskName && errors.field[index].taskName.message}</small>
                  </div>
                  <div className="col-6 mb-2">
                    <Controller
                      name={`field[${index}].group`}
                      control={control}
                      rules={{ required: 'Select a group' }}
                      render={({ field }) => <Select {...field} options={renderGroups()} isMulti={false} />}
                    />
                    <small className="text-danger">{errors?.field?.[index]?.group && errors.field?.[index]?.group.message}</small>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-stack pt-10">
        <div className="me-2">
          <button type="button" className="btn btn-lg btn-light-primary me-3" disabled={!prevStep.value} onClick={handlePreviousClick}>
            <i className="fa-solid fa-arrow-left text-primary fs-6"></i> Back
          </button>
        </div>
        <div>
          <button type="submit" id="submitButton" className="btn btn-lg btn-primary" disabled={!nextStep.value || fields.length === 0}>
            Continue <i className="fa-solid fa-arrow-right fs-6 ms-2"></i>
          </button>
        </div>
      </div>
    </form>
  );
}

export default Tasks;
