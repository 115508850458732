const priorityConstants = {
  CRITICAL_PRIORITY_ID: 1,
  HIGH_PRIORITY_ID: 2,
  MED_PRIORITY_ID: 3,
  LOW_PRIORITY_ID: 4,
};

const commentFlagConstants = {
  ACCEPTED_COMMENT_FLAG_ID: 1,
  REJECTED_COMMENT_FLAG_ID: 2,
  NEUTRAL_COMMENT_FLAG_ID: 3,
};

const worklistStatusIdConstants = {
  TODO_STATUS_ID: 1,
  IN_PROGRESS_STATUS_ID: 2,
  COMPLETED_STATUS_ID: 3,
};

const userRoleConstants = {
  ADMIN_ID: 1,
  USER_ID: 2,
};

const milestoneAndStatusIdConstants = {
  TODO_STATUS_ID: 1,
  IN_PROGRESS_STATUS_ID: 2,
  COMPLETED_STATUS_ID: 3,
};

const DURATION_GLOBAL = /^[0-9]{1,2}:[0-5]{0,1}[0-9]{1}$|^[0-9]{1,2}h[0-5]{0,1}[0-9]{1}m$|^[0-9]{1,2}h$|^[0-5]{0,1}[0-9]{1}m$|^[0-9]{0,2}\.[0-9]{1,2}$/;


module.exports = {
  worklistStatusIdConstants,
  priorityConstants,
  commentFlagConstants,
  userRoleConstants,
  milestoneAndStatusIdConstants,
  DURATION_GLOBAL
};
