import React, { useContext, useState } from 'react';
import CreatableInput from './CreatableInput';
import AppContext from '../../../../../Context/AppContext';

const initialGroups = [
  {
    label: 'Performance Test',
    value: 'Performance Test',
  },
  {
    label: 'Production line design',
    value: 'Production line design',
  },
  {
    label: 'Factory layout and design',
    value: 'Factory layout and design',
  },
];

const allGroups = [
  ...initialGroups,
  {
    label: 'Performance Analysis',
    value: 'Performance Analysis',
  },
  {
    label: 'Dismantling of existing motors',
    value: 'Dismantling of existing motors',
  },
  {
    label: 'BLDC Motor development',
    value: 'BLDC Motor development',
  },
  {
    label: 'CAD drawings',
    value: 'CAD drawings',
  },
  {
    label: 'Prototyping',
    value: 'Prototyping',
  },
];

function Groups({ prevStep, nextStep, setActiveStep }) {
  const { templateData, setTemplateData } = useContext(AppContext);
  const [groups, setGroups] = useState(templateData?.groups.length > 0 ? templateData.groups : initialGroups);

  const handleNextClick = () => {
    if (groups.length === 0) {
      window.toastr.error('Please add a group');
      return;
    }
    setActiveStep(nextStep);
  };

  const handlePreviousClick = (e) => {
    e.preventDefault();
    setActiveStep(prevStep);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setTemplateData((templateData) => ({ ...templateData, groups }));
    handleNextClick();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div data-kt-stepper-element="content" className="current mb-auto pb-5">
        <div className="w-100">
          <div className="fv-row">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-1">
              <span className="required">Groups in template</span>
            </label>
            <p className="text-muted">Groups can help you organize/ categorize your tasks</p>
            <CreatableInput placeholderString="Enter a group name and press enter..." value={groups} setValue={setGroups} options={[...allGroups]} />
            {groups.length === 0 && <span className="text-danger">Please add a group</span>}
          </div>
        </div>
      </div>

      <div className="d-flex flex-stack pt-10 mt-10">
        <div className="me-2">
          <button type="button" className="btn btn-lg btn-light-primary me-3" disabled={!prevStep.value} onClick={handlePreviousClick}>
            <i className="fa-solid fa-arrow-left text-primary fs-6"></i> Back
          </button>
        </div>
        <div>
          <button type="submit" className="btn btn-lg btn-primary" disabled={!nextStep.value || groups.length === 0}>
            Continue <i className="fa-solid fa-arrow-right fs-6 ms-2"></i>
          </button>
        </div>
      </div>
    </form>
  );
}

export default Groups;
