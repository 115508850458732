import React from 'react';

function WizardSteps({ steps = [], activeStep = { value: 'info', index: 0 }, setActiveStep }) {
  const handleStepChange = (e, step) => {
    e.preventDefault();
    if (!step.value === 'done') {
      setActiveStep(step);
    }
  };

  return (
    <>
      {Array.isArray(steps) &&
        steps.length > 0 &&
        steps.map((step, index) => (
          <span key={index}>
            {/* <div key={index} className={`step ${step.value === activeStep.value ? 'active' : ''} ${activeStep.index > index ? 'crossed' : ''}`}>
              <button type="button" className="step-trigger" onClick={(e) => handleStepChange(e, { value: step.value, index: index })}>
                <span className="bs-stepper-circle">{index + 1}</span>
                <span className="bs-stepper-label">
                  <span className="bs-stepper-title">{step.label}</span>
                </span>
              </button>
            </div> */}
            <div className={`stepper-item ${step.value === activeStep.value ? 'current' : ''}`} data-kt-stepper-element="nav">
              <div role="none" className="stepper-wrapper" onClick={(e) => handleStepChange(e, step)}>
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check fas fa-check"></i>
                  <span className="stepper-number">{index + 1}</span>
                </div>
                <div className="stepper-label">
                  <h3 className="stepper-title">{step.label}</h3>
                  <div className="stepper-desc">{step.sub_label}</div>
                </div>
              </div>
              {index < steps.length - 1 ? <div className="stepper-line h-40px"></div> : null}
            </div>
          </span>
        ))}
    </>
  );
}

export default WizardSteps;
