// import { axios } from "../../utils/axios";
const baseUrl = process.env.REACT_APP_API_HOST;

export const getUserDetail = (authToken = "") => {
  authToken = authToken === "" || authToken == null ? localStorage.getItem("token") : authToken;
  try {
    return fetch(`${baseUrl}/users/profile`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log(error);
  }
};