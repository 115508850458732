import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/Auth/SignIn/Index";
// import ChartDetails from "./components/ChartDetails/ChartDetails";
// import UserHome from "./components/Coder/Index";
import Layout from "./components/Layout/Layout";
// import CoderProfile from "./components/CoderProfile/CoderProfile";
import AuthProvider from "./components/Auth/Middleware/AuthProvider";
import PR from "./components/Auth/Middleware/ProtectedRoute";
import ChartContextProvider from "./Context/ChartContextProvider";
import AppContextProvider from "./Context/AppContextProvider";
// import AuditorHome from "./components/Auditor/index";
// import WorkList from "./components/Manager/ManagerWorkLists/Index";
// import ManagerReports from "./components/Manager/ManagerReports/Index";
import WorklistDetails from "./components/Manager/ManagerWorklistDetails/Index";
// import Configuration from './components/Configuration/index';
import CommentContextProvider from "./Context/CommentContext/CommentContextProvider";
// import ManagerReportContextProvider from "./Context/ManagerReportContext/ManagerReportContextProvider"
// import UserList from "./components/Manager/ManagerUsers/Index";
// import Index from "./components/Dashboard/Index";
// import DashboardContextProvider from "./Context/DashboardContext/DashboardContextProvider";
// import ManagerUsersProfile from "./components/Manager/ManagerUsersProfile";
import AdAuthRedirect from './components/Auth/Middleware/AdAuthRedirect';
import { MsalProvider } from "@azure/msal-react";
import KanbanBoard from './components/Sujata/KanbanBoard/KanbanBoard';
import Timesheet from "./components/Sujata/Timesheet/TimesheetTable";
import Dashboard from "./components/Sujata/Dashboard";
import ProductList from './components/Sujata/Products/List';
import TaskDetails from "./components/Sujata/TaskDetails/TaskDetails";
import ProductDetails from "./components/Sujata/Products/ProductDetails";
import TasksList from './components/Sujata/Tasks/TasksList';


function App({ instance }) {

  return (
    <>
      <MsalProvider instance={instance}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route exact path="/login" element={<SignIn />} />
            <Route exact path="/redirect" element={ <AdAuthRedirect /> } />
            <Route element={<AppContextProvider> <Layout /> </AppContextProvider>}>
              {/* PR - Protected Routes */}
                <Route exact path="/" element={ <PR> <Dashboard /> </PR> } />
                <Route exact path="/tasks" element={ <PR> <TasksList /></PR> } />
                <Route exact path="/task/:unique_task_no" element={ <PR> <ChartContextProvider> <CommentContextProvider> <TaskDetails /> </CommentContextProvider> </ChartContextProvider> </PR> } />
                <Route exact path="/manager-worklist" element={ <PR>  <Dashboard /> </PR> } />
                <Route exact path="/product-list" element= { <PR> <ProductList /> </PR> } />
                <Route exact path="/products/:product_id" element= { <PR> <ProductDetails /> </PR> } />
                <Route exact path="/manager-worklist-details" element={ <PR> <ChartContextProvider> <WorklistDetails /> </ChartContextProvider> </PR> } />
                <Route exact path="/timesheet" element={ <PR> <ChartContextProvider>  <Timesheet/> </ChartContextProvider> </PR> } />
                <Route exact path="/kanban" element= { <PR> <KanbanBoard /> </PR> } />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
      </MsalProvider>
    </>
  );
}

export default App;
