import React, { useState, useEffect, useContext, useRef } from 'react';
import GroupList from './GroupList';
import { Link, useParams } from 'react-router-dom';
import { getMasterData, getProductById } from '../../../../services/sujata/products';
import Chart from 'react-apexcharts';
import CountUp from 'react-countup';
import EditProductModal from '../EditProductModal';
import AppContext from '../../../../Context/AppContext';

function ProductDetails() {
  const { product_id } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const [series, setSeries] = useState([1, 1, 1]);
  const [productFlag, setProductFlag] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { setMasterData } = useContext(AppContext);
  const quickActionRef = useRef();

  const handleEditModal = (e) => {
    e.preventDefault();
    setShowEditModal(true);
  };

  const labels = ['Completed', 'In Progress', 'To Do'];

  const bindReplyEvents = () => {
    document.querySelectorAll('.product-actions .menu-item').forEach((link) => {
      link.addEventListener('click', (event) => {
        const action = event.target.getAttribute('data-action-type');
        if (action === 'openEditModal') {
          handleEditModal(event);
        }
      });
    });
  };

  useEffect(() => {
    getProductById(product_id).then((response) => {
      if (response.success) {
        setProductDetails(response.data);
        setSeries([
          response.data?.milestoneCounts?.completedTask ?? 0,
          response.data?.milestoneCounts?.inprogressTask ?? 0,
          response.data?.milestoneCounts?.todoTask ?? 0,
        ]);
        setTimeout(() => {
          window.KTMenu.init();
          bindReplyEvents();
        }, 500);
      }
    });
    getMasterData()
      .then((response) => {
        if (response?.success) {
          setMasterData(response.data);
        }
      })
      .catch((error) => {
        window.toastr.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id, setProductDetails, productFlag]);

  const closeModal = () => {
    setShowEditModal(false);
  };

  useEffect(() => {
    const menu = quickActionRef?.current;
    return () => {
      if (menu) {
        menu.classList.remove('show');
      }
    };
    
  }, [])


  return (
    <>
      <div className="d-flex justify-content-between progress-button mt-5">
        <div>
          <h2 className="pb-0 mb-0 ms-0 pt-0">Product details</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home{' '}
              </Link>
            </li>
            <li className="breadcrumb-item text-muted">
              <Link to="/product-list" className="text-muted text-hover-primary">
                Products{' '}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-900">Product details </li>
          </ul>
        </div>
        <div>
          <Link
            to="/kanban"
            state={{ product_id: { value: product_id, label: productDetails?.name } }}
            type="button"
            className="btn btn-sm btn-primary mx-1"
          >
            <i className="fa-regular fa-window-restore me-1"></i>Kanban
          </Link>
          <Link
            to="/timesheet"
            state={{ product_id: { value: product_id, label: productDetails?.name } }}
            type="button"
            className="btn btn-sm btn-secondary"
          >
            <i className="fa-regular fa-clock me-1"></i>Timesheet
          </Link>
        </div>
        {/* <div>
            <a target="_blank" href="http://13.234.217.63/websites/sujata-product-strategy/v0.7/configuration.html">
              Go To Strategy
            </a>
            <a href="kanban.html" type="button" className="btn btn-sm btn-primary ms-3">
              <i className="fa-regular fa-window-restore me-1"></i>Kanban
            </a>
            <a href="timesheet.html" type="button" className="btn btn-sm btn-secondary">
              <i className="fa-regular fa-clock me-1"></i>Timesheet
            </a>
          </div> */}
      </div>
      <div className="row mt-5 pt-0">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="row gy-3 g-xl-3">
            <div className="col-xl-5">
              <div className="card card-flush h-100">
                <div className="card-header pb-0">
                  <h3 className="card-title align-items-start flex-column mb-0">
                    <div className="d-flex align-items-center mb-0">
                      <h1 className="fs-2 fw-bold me-1">{productDetails?.name ?? ''}</h1>
                      <span className={`badge fs-base ${productDetails?.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'}`}>
                        {productDetails?.status ?? ''}
                      </span>
                    </div>
                  </h3>
                  {/* Dropdown Menu */}
                  <div className="card-toolbar">
                    <div
                      className=" btn-color-gray-400 btn-active-color-primary justify-content-end"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-overflow="true"
                    >
                      <span className="svg-icon svg-icon-1 svg-icon-gray-600 me-n1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor" />
                          <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                          <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                          <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor" />
                        </svg>
                      </span>
                    </div>
                    <div
                      ref={quickActionRef}
                      className="product-actions menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                      data-kt-menu="true"
                    >
                      <div className="menu-item px-3">
                        <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                      </div>
                      <div className="separator mb-3 opacity-75"></div>
                      <div className="menu-item px-3">
                        <div role="button" className="menu-link px-3" data-action-type="openEditModal">
                          Edit info
                        </div>
                      </div>
                      {/* <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            View history
                          </a>
                        </div> */}
                    </div>
                  </div>
                </div>

                <div className="card-body py-2">
                  <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                    <div className="flex-grow-1">
                      <div className="d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2">
                        <p className="d-flex align-items-center text-hover-primary me-5 mb-2">
                          <span className="svg-icon svg-icon-4 me-1">
                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.3"
                                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z"
                                fill="currentColor"
                              ></path>
                              <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect>
                              <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect>
                              <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect>
                              <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
                            </svg>
                          </span>
                          Type: <strong className="ms-2"> {productDetails?.type ?? ''}</strong>
                        </p>
                        <p className="d-flex align-items-center text-hover-primary mb-2 me-5">
                          <span className="svg-icon svg-icon-4 me-1">
                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z"
                                fill="currentColor"
                              ></path>
                              <path
                                opacity="0.3"
                                d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                          Duration: <strong className="ms-2">{productDetails?.duration ?? 0} Months</strong>
                        </p>
                      </div>
                      <div className="d-flex flex-wrap flex-stack">
                        <div className="d-flex flex-column flex-grow-1">
                          <div className="d-flex flex-wrap">
                            <div className=" min-w-100px pt-3 px-4 me-4">
                              <div className="d-flex align-items-center">
                                <div className="fs-5 fw-bold" data-kt-countup="false" data-kt-countup-value="75">
                                  {productDetails?.start_date}
                                </div>
                              </div>
                              <div className="fw-semibold fs-6 text-gray-400">Start date</div>
                            </div>
                            <div className=" min-w-100px pt-3 px-4 me-4">
                              <div className="d-flex align-items-center">
                                <div className="fs-5 fw-bold" data-kt-countup="false" data-kt-countup-value="75">
                                  {productDetails?.end_date}
                                </div>
                              </div>
                              <div className="fw-semibold fs-6 text-gray-400">End date</div>
                            </div>
                            <div className="me-4">
                              <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Owner</span>
                              <div
                                className="symbol symbol-25px symbol-circle"
                                data-bs-toggle="tooltip"
                                title={`${productDetails?.user_firstname ?? ''} ${productDetails?.user_lastname ?? ''}`}
                              >
                                {productDetails?.user_image_url ? (
                                  <img alt="Pic" src={productDetails?.user_image_url} />
                                ) : (
                                  <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                                    {productDetails?.user_firstname?.[0] ?? ''}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex flex-column justify-content-end pe-0">
                              <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Members</span>
                              <div className="symbol-group symbol-hover flex-nowrap">
                                {productDetails?.productMember?.length > 0 &&
                                  productDetails.productMember.map((member) => (
                                    <div
                                      key={member.id}
                                      className="symbol symbol-25px symbol-circle"
                                      data-bs-toggle="tooltip"
                                      title={`${member.first_name} ${member.last_name}`}
                                    >
                                      {member.image_url ? (
                                        <img alt="Pic" src={member?.image_url} />
                                      ) : (
                                        <span className="symbol-label bg-warning text-inverse-warning fw-bold">{member.first_name[0] ?? ''}</span>
                                      )}
                                    </div>
                                  ))}

                                {/* <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="Sweeti Rajput">
                                    <span className="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                                  </div>

                                  <div className="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" title="Preeti Gupta">
                                    <span className="symbol-label bg-danger text-inverse-danger fw-bold">P</span>
                                  </div>

                                  <a
                                    href="#"
                                    className="symbol symbol-25px symbol-circle"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_view_users"
                                  >
                                    <span className="symbol-label fs-8 fw-bold">+1</span>
                                  </a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="card card-flush h-100">
                <div className="card-body pt-6 pb-6 align-items-center">
                  <div className="row">
                    <div className="col-md-4 col-12 h-100">
                      <div className="card-title d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{productDetails?.progressPercentage ?? '0'}%</span>
                        </div>
                        <span className="text-gray-400 pt-1 pb-1 fw-semibold fs-6">Product progress</span>
                      </div>
                    </div>
                    {/* Chart */}
                    <div className="col-md-8 col-12 d-flex">
                      <div className="flex-center me-6 pt-2 w-50">
                        <div style={{ width: '150px', height: '100px' }}>
                          <div dir="ltr" style={{ position: 'relative', width: 150, height: 210 }}>
                            <div aria-label="A chart." style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
                              <Chart
                                options={{
                                  labels,
                                  legend: { show: false },
                                  dataLabels: { enabled: false },
                                  plotOptions: {
                                    pie: {
                                      donut: {
                                        labels: {
                                          show: true,
                                          name: { show: false },
                                          value: {
                                            show: false,
                                            color: 'var(--bs-text-dark)',
                                            fontFamily: 'var(--bs-font-sans-serif)',
                                            fontSize: '2rem',
                                            formatter: function (productDetail) {
                                              return productDetail?.total_tasks ?? 'Default';
                                            },
                                          },
                                          total: {
                                            show: false,
                                            label: '',
                                          },
                                        },
                                      },
                                    },
                                  },
                                  colors: ['#5DCD89', '#F3993E', '#DC3A2F'],
                                }}
                                series={series}
                                type="donut"
                                width="150px"
                              />
                              <div
                                aria-label="A tabular representation of the data in the chart."
                                style={{ position: 'absolute', left: '-10000px', top: 'auto', width: 1, height: 2, overflow: 'hidden' }}
                              ></div>
                            </div>
                          </div>
                          <div
                            aria-hidden="true"
                            style={{
                              display: 'none',
                              position: 'absolute',
                              top: 130,
                              left: 130,
                              whiteSpace: 'nowrap',
                              fontFamily: 'Arial',
                              fontSize: 8,
                              fontWeight: 'bold',
                            }}
                          ></div>
                          <div />
                        </div>
                      </div>

                      <div className="flex-column content-justify-center w-100 mt-5 fs-8">
                        <div className="d-flex fw-semibold align-items-center">
                          <div className="bullet w-8px h-8px rounded-2 bg-danger me-3"></div>
                          <div className="text-gray-500 flex-grow-1 me-4">To do</div>
                          <div className="fw-bolder text-gray-700 text-xxl-end">{productDetails?.milestoneCounts?.todoTask ?? '0'}</div>
                        </div>
                        <div className="d-flex fw-semibold align-items-center my-3">
                          <div className="bullet w-8px h-8px rounded-2 bg-warning me-3"></div>
                          <div className="text-gray-500 flex-grow-1 me-4">In progress</div>
                          <div className="fw-bolder text-gray-700 text-xxl-end">{productDetails?.milestoneCounts?.inprogressTask ?? '0'}</div>
                        </div>
                        <div className="d-flex fw-semibold align-items-center">
                          <div className="bullet w-8px h-8px rounded-2 me-3 bg-success"></div>
                          <div className="text-gray-500 flex-grow-1 me-4">Completed</div>
                          <div className="fw-bolder text-gray-700 text-xxl-end">{productDetails?.milestoneCounts?.completedTask ?? '0'}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-12 d-flex justify-content-between mt-5">
                      <p className="mb-0 fw-bold fs-5">
                        Total Tasks : <span className="text-primary">{productDetails?.milestoneCounts?.totalTask ?? '0'}</span>
                      </p>
                      <p className="mb-0">
                        <Link to="/tasks" state={{ product_id: { value: product_id, label: productDetails?.name } }}>
                          View all <i className="fa-solid fa-arrow-right text-primary ms-2"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3">
              <div className="card-flush ">
                <div className="card-body">
                  <div className="row p-0 ">
                    <div className="col-6">
                      <div className="border border-warning border-dashed border-gray-300 text-center rounded py-2 bg-light-warning">
                        <CountUp className="fs-2hx fw-bold text-gray-900" end={productDetails?.daysToGo} />
                        <span className="fs-4 fw-semibold text-warning d-block">Days to Go</span>
                      </div>
                    </div>

                    <div className="col-6">
                      <div>
                        <div className="border border-primary border-dashed border-gray-300 text-center rounded py-2 bg-light-primary">
                          <CountUp end={productDetails?.taskOverdue} className="fs-2hx fw-bold text-gray-900" />
                          <span className="fs-4 fw-semibold text-primary d-block">Tasks overdue</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-2">
                      <Link to="/timesheet" state={{ product_id: { value: product_id, label: productDetails?.name } }}>
                        <div className="border border-primary border-dashed border-gray-300 text-center rounded py-2 justify-content-center">
                          <div className="d-flex justify-content-between d-grid px-9 my-2">
                            <div className="me-md-0">
                              <div className="d-flex justify-content-center mb-2">
                                <span className="fs-3 fw-bold text-gray-800 me-2 ">
                                  {productDetails?.currentMonthLogHours ? parseFloat(productDetails.currentMonthLogHours) : 0}
                                  <small className="text-muted fs-6 fw-normal"> hours</small>
                                </span>
                              </div>
                              <span className="fs-5 d-flex justify-content-center text-gray-900">This month</span>
                            </div>
                            <div className="border-start-dashed border-gray-300"></div>
                            <div className="">
                              <div className="d-flex justify-content-center mb-2">
                                <span className="fs-3 fw-bold text-gray-800 me-2">
                                  {productDetails?.totalLogHours ? parseFloat(productDetails.totalLogHours) : 0}
                                  <small className="text-muted fw-normal fs-6"> hours</small>
                                </span>
                              </div>
                              <span className="fs-5 fw-semibold d-flex justify-content-center text-gray-900">Total logged</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GroupList
        product_id={productDetails?.id}
        productFlag={productFlag}
        setProductFlag={setProductFlag}
        productMembers={productDetails?.productMember}
        productDetails={productDetails}
      />

      {showEditModal && (
        <EditProductModal
          show={showEditModal}
          onHide={closeModal}
          productDetails={productDetails}
          success={productFlag}
          setSuccess={setProductFlag}
        />
      )}
    </>
  );
}

export default ProductDetails;
