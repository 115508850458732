import EpicDetailsModal from "./EpicSprintManagement/EpicDetailsModal";
import { useState, useEffect } from "react";
import localStorageStore from "../../../utils/localStorageStore";
import role from "../../../utils/role";
import Swal from "sweetalert2";
import { deleteEpic } from "../../../services/managerWorkListService";
import { fetchProjectEpicsAndSprints } from "../../../services/managerWorkListService";

const EpicManagement = ({ worklistId, success, setSuccess }) => {
  const [showMainModalBox, setShowMainModalBox] = useState(false);
  const [modalValues, setModalValues] = useState({ worklist_id: worklistId });
  const [epics, setEpics] = useState([]);

  useEffect(() => {
    fetchProjectEpicsAndSprints(worklistId).then((response) => {
      setEpics(response?.data?.epics);
    });
  }, [worklistId, success]);

  const closeTaskModal = () => {
    setShowMainModalBox(false);
  };
  async function handleShowDeleteTask(id) {
    Swal.fire({
      text: "Are you sure you would like to delete this Epic?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-active-light",
      },
    }).then(async function (result) {
      if (result?.value === true) {
        deleteEpic(id).then((res) => {
          if (res?.success === true) {
            window.toastr.success(res?.message);
            setSuccess(!success);
          } else {
            window.toastr.error(res?.message);
          }
        });
      }
    });
  }

  const handleEpicUpdateClick = (e, item) => {
    e.preventDefault();
    setModalValues({ ...modalValues, id: item?.value, name: item?.label, description: item?.description, modal_type: "update" });
    setShowMainModalBox(true);
  };

  const handleEpicAddClick = (e) => {
    e.preventDefault();
    setModalValues({ worklist_id: modalValues.worklist_id, modal_type: "add" });
    setShowMainModalBox(true);
  };

  const decryptRole = localStorageStore.getRole();
  return (
    <div className="col-xl-5">
      <div className="card card-flush mb-6 mb-xl-9">
        <div className="card-header py-4">
          <p className="card-title align-items-start flex-column">
            <span className="fw-bold h4" style={{ maxWidth: "400px" }}>
              EPICS
            </span>
          </p>
          {(decryptRole === role.Manager || decryptRole === role.Admin) && (
            <div className="text-left pt-2">
              <button type="button" className="btn btn-primary" onClick={handleEpicAddClick}>
                <span className="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                  </svg>
                </span>
                Add Epic
              </button>
            </div>
          )}
        </div>
        <div className="card-body py-2 mb-6" style={{ maxHeight: "205px", overflow: "auto" }}>
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5">
              <thead>
                <tr className="text-start text-dark fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-80px ps-2">Name</th>
                  <th className="min-w-175px">Progress</th>
                  {(decryptRole === role.Manager || decryptRole === role.Admin) && <th className="min-w-80px">Action</th>}
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-semibold">
                {epics?.map((item, index) => (
                  <tr key={item?.value}>
                    <td>
                      <strong className="text-gray-900">{item?.label}</strong>
                    </td>
                    <td>
                      <div className="d-flex align-items-center flex-column mt-3 w-100">
                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                          {/* {item?.total_tasks_count !== null && (
                            <>
                              <span className="fw-bold fs-6 text-gray-400">{(parseInt(item?.total_tasks_count) !== 0 ? 100 - (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0).toFixed(2)}%</span>
                              <span className="fw-semibold fs-6 text-dark">{(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0).toFixed(2)}%</span>
                            </>
                          )} */}
                          {item?.total_tasks_count !== null && (
                            <>
                              {parseInt(item?.total_tasks_count) !== 0 && parseInt(item?.unapproved_tasks) !== 0 ? (
                                <>
                                  <span className="fw-bold fs-6 text-gray-400">{`${item?.unapproved_tasks}/${item?.total_tasks_count} pending`}</span>
                                  <span className="fw-bold fs-6 text-gray-400">{(100 - (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100).toFixed(2)}%</span>
                                  {/* <span className="fw-semibold fs-6 text-dark">{((parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100).toFixed(2)}%</span> */}
                                </>
                              ) : parseInt(item?.total_tasks_count) !== 0 && parseInt(item?.unapproved_tasks) === 0 ? (
                                <div>Completed</div>
                              ) : (
                                <div>No Tasks</div>
                              )}
                            </>
                          )}
                        </div>
                        {item?.total_tasks_count !== null ? (
                          <div className={(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : "No Tasks") === 0 ? "h-8px mx-3 w-100 bg-success rounded" : "h-8px mx-3 w-100 bg-light-warning rounded"}>
                            <div className={(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : "No Tasks") === 0 ? "bg-success rounded h-8px" : "bg-warning rounded h-8px"} role="progressbar" style={(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0) === 0 ? { width: "100%", ariaValuenow: "50", ariaValuemin: "0", ariaValuemax: "100" } : { width: `${parseInt(item?.total_tasks_count) !== 0 ? 100 - (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0}%`, ariaValuenow: "50", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                          </div>
                        ) : (
                          <div>---</div>
                        )}
                      </div>
                    </td>
                    <td>
                      {(decryptRole === role.Manager || decryptRole === role.Admin) && (
                        <>
                          <span style={{ fontSize: "10px !important" }} onClick={(e) => handleEpicUpdateClick(e, item)}>
                            <i className="fas fa-pen ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                          </span>{" "}
                          |
                          <span style={{ fontSize: "10px !important" }} onClick={(e) => handleShowDeleteTask(item?.value)}>
                            <i className="fas fa-trash ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                          </span>{" "}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {epics?.length === 0 && <div className="my-3">No Records Found ...</div>}
          </div>
        </div>
      </div>
      {showMainModalBox && <EpicDetailsModal showMainModalBox={showMainModalBox} closeTaskModal={closeTaskModal} setSuccess={setSuccess} success={success} modalValues={modalValues} />}
    </div>
  );
};
export default EpicManagement;
