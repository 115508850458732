import { axios } from '../../utils/axios';
const baseUrl = process.env.REACT_APP_API_HOST;

export const getTaskDetails = async (id) => {
  try {
    return await axios.get(`${baseUrl}/tasks/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => res);
  } catch (error) {
    console.log(error);
  }
};

export const updateTask = async (id, data) => {
  try {
    return await axios.post(`${baseUrl}/tasks/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => res);
  } catch (error) {
    console.log(error);
  }
}

export const getTaskActivityLogData = (id) => {
  try {
    return axios.get(`${baseUrl}/tasks/${id}/activity-log`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res);
  } catch (error) {
    console.log(error);
  }
};

export const getTasksByFilter = (data, page, size) => {
  try {
    return axios.post(`${baseUrl}/tasks/filter?page=${page}&size=${size}`,data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res);
  } catch (error) {
    console.log(error);
  }
}

export const addTask = async (data) => {
  try {
    return await axios.post(`${baseUrl}/tasks`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((res) => res);
  } catch (error) {
    console.log(error);
  }
}

export const getLogHours = (id) => {
  try {
    return axios.get(`${baseUrl}/tasks/${id}/log-hours`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res);
  } catch (error) {
    throw error;
  }
};

export const addLogHours = (id, data) => {
  try {
    return axios.post(`${baseUrl}/tasks/${id}/log-hours`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  }
  catch (error) {
    console.log(error);
  }
}

export const updateLogHours = (id, log_id, data) => {
  try {
    return axios.put(`${baseUrl}/tasks/${id}/log-hours/${log_id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  }
  catch (error) {
    console.log(error);
  }
}

export const getAllGroupsAndTasks = () => {
  try {
    return axios.get(`${baseUrl}/tasks/filter-options`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => res);
  }
  catch (error) {
    console.log(error);
  }
}