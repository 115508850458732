import React from 'react';
import { Modal } from 'react-bootstrap';
import TemplateWizard from './TemplateWizard';

function EditTemplateModal({ show, onHide, setShowAddModal, success, setSuccess }) {
  return (
    <>
      <Modal show={show} onHide={onHide} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Edit Template</h2>
            <p className="fs-6 fw-semibold text-muted mb-0">
              Templates enable you to define once and re-use the product structure including groups and tasks.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper">
            <TemplateWizard
              wizardType={'edit'}
              hideTemplateModal={onHide}
              setShowAddProductModal={setShowAddModal}
              success={success}
              setSuccess={setSuccess}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditTemplateModal;
