import React from 'react';

function Done({ hideTemplateModal, setShowAddProductModal, wizardType }) {
  const handleCreateProduct = () => {
    hideTemplateModal();
    setTimeout(() => {
      setShowAddProductModal({ show: true });
    }, 300);
  };

  return (
    <>
      <div className="current" data-kt-stepper-element="content">
        <div className="w-100 text-center">
          <h1 className="fw-bold text-dark mb-3">Template {wizardType === 'add' ? 'created' : 'edited'}!</h1>
          <div className="text-muted fw-semibold fs-3">Great, you can create a product now!</div>
          <div className="text-center px-4">
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/9.png`} alt="product_created" className="mw-100 mh-200px" />
          </div>
        </div>
      </div>
      <div className="d-flex flex-stack pt-10">
        <div className="me-2">
          {/* <button type="button" className="btn btn-lg btn-light-primary me-3" disabled={!prevStep.value} onClick={handlePreviousClick}>
            <i className="fa-solid fa-arrow-left text-primary fs-6"></i> Back
          </button> */}
        </div>
        <div>
          <button type="button" className="btn btn-lg btn-primary" onClick={handleCreateProduct}>
            <span className="indicator-label">
              Create a Product
              <i className="fa-solid fa-arrow-right fs-6 ms-2"></i>
              {/* <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span> */}
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Done;
