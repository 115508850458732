import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { filterWithGetAllTimesheet, getReports } from '../../../services/sujata/timesheet';
import localStorageStore from '../../../utils/localStorageStore';
import role from '../../../utils/role';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import AppContext from '../../../Context/AppContext';

const TimesheetTable = () => {
  const { state } = useLocation();
  const fields = [
    'unique_task_no',
    'name',
    'hours',
    'description',
    'hours_calc',
    'date',
    'start_date',
    'end_date',
    'product_no',
    'product_name',
    'assignee_name',
    'milestone_name',
  ];

  const renderLogDate = () => {
    const logDate = [
      { label: 'January', value: '1' },
      { label: 'February', value: '2' },
      { label: 'March', value: '3' },
      { label: 'April', value: '4' },
      { label: 'May', value: '5' },
      { label: 'June', value: '6' },
      { label: 'July', value: '7' },
      { label: 'August', value: '8' },
      { label: 'September', value: '9' },
      { label: 'October', value: '10' },
      { label: 'November', value: '11' },
      { label: 'December', value: '12' },
    ];

    return logDate;
  };
  const currentMonth = moment().format('M');

  const decryptRole = localStorageStore.getRole();
  const [selectedProductName, setSelectedProductName] = useState([]);
  const [users, setUsers] = useState([]);
  const [productList, setProductList] = useState([]);
  const [showTable, setShowTable] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(state?.product_id ? [state.product_id] : []);
  const [selectedUserId, setSelectedUserId] = useState(decryptRole === role.TeamLead ? [localStorage.getItem('loginUserId')] : []);
  const [selectedLogDate, setSelectedLogDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(renderLogDate().find((option) => option.value === currentMonth));
  const [show, setShow] = useState(false);
  const [currentUser, setCurrentUser] = useState([]);
  const [clearFilterFlag, setClearFilterFlag] = useState(false);

  const { masterData } = useContext(AppContext);

  useEffect(() => {
    setProductList(masterData?.product_names);
    setUsers(masterData?.users_all);
  }, [masterData]);

  const firstDay = moment(`${selectedLogDate || currentMonth}`, 'M')
    .startOf('month')
    .format('YYYY-MM-DD');
  const lastDay = moment(`${selectedLogDate || currentMonth}`, 'M')
    .endOf('month')
    .format('YYYY-MM-DD');

  let obj = {
    users: selectedUserId,
    products: selectedProduct.map((product) => product?.value),
    month: selectedLogDate || currentMonth, // Use selectedLogDate if available, otherwise use currentMonth
  };

  let filterPayload = {};

  if (selectedUserId && selectedUserId?.length > 0) {
    filterPayload.assignee_name = selectedUserId;
  }

  if (firstDay && lastDay) {
    filterPayload.date = `${firstDay} - ${lastDay}`;
  }

  if (selectedProductName && selectedProductName?.length > 0) {
    filterPayload.product_name = selectedProductName;
  }

  const handleExport = async () => {
    fetchReports(fields, true);
  };

  const renderProductOptions = () => {
    return productList?.map((project) => ({
      label: `${project?.name}`,
      value: project?.id,
    }));
  };

  const handleProductChange = (selectedOption) => {
    const productName = selectedOption?.map((item) => item?.label) || [];
    const productNameForExport = productName?.map((entry) => entry?.replace(/\([^)]+\)/, '').trim());
    setSelectedProduct(selectedOption);
    setSelectedProductName(productNameForExport);
  };

  const renderUserOptions = () => {
    return users?.map((user) => ({
      label: `${user?.name}`,
      value: user?.id,
    }));
  };

  const handleUserDetails = (event) => {
    setCurrentUser(event);
    const selectedUserIds = event?.map((item) => item.value) || [];
    setSelectedUserId(selectedUserIds);
  };

  const handleLogDateChange = (selectedOption) => {
    const selectedMonth = parseInt(selectedOption?.value);
    setSelectedLogDate(selectedMonth);
    setSelectedMonth(selectedOption);
  };

  const handleSubmitFilter = async () => {
    const fetchTimesheetList = await filterWithGetAllTimesheet(obj);
    setShowTable(fetchTimesheetList?.data);
  };

  const handleClearFilter = async () => {
    setCurrentUser([]);
    setSelectedProduct([]);
    setSelectedLogDate('');
    setSelectedMonth(() => renderLogDate().find((option) => option.value === currentMonth));
    setSelectedUserId([]);
    setSelectedProductName([]);
    setClearFilterFlag(!clearFilterFlag);
  };

  useEffect(() => {
    handleSubmitFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilterFlag]);

  const calculateTotalsDays = () => {
    const totals = {};
    // Loop through the records and update the totals for each column
    showTable?.records?.forEach((record) => {
      record?.data?.forEach((rowData, j) => {
        if (j >= 3) {
          if (!totals[j]) {
            totals[j] = 0;
          }
          totals[j] += rowData.row_data || 0;
        }
      });
    });
    for (const key in totals) {
      if (totals.hasOwnProperty(key)) {
        totals[key] = parseFloat(totals[key].toFixed(2));
      }
    }

    return totals;
  };
  const totalValuesDays = calculateTotalsDays();

  const calculateTotals = () => {
    const totals = {};
    // Loop through the records and update the totals for each column
    showTable?.records?.forEach((record) => {
      record?.data?.forEach((rowData, j) => {
        if (j === 2) {
          if (!totals[j]) {
            totals[j] = 0;
          }
          totals[j] += rowData.row_data || 0;
        }
      });
    });
    for (const key in totals) {
      if (totals.hasOwnProperty(key)) {
        totals[key] = parseFloat(totals[key].toFixed(2));
      }
    }
    return totals;
  };
  const totalValues = calculateTotals();

  const handleViewLog = async (project_id, user_id, date) => {
    setShow(!show);
  };

  const fetchReports = async (fields, exportFile = false, isLocal, forcePage = false) => {
    try {
      let res = await getReports(
        {
          export: exportFile,
          fields: fields,
          filters: filterPayload,
        },
        {
          page: forcePage ? forcePage : 1,
        },
        exportFile
      );
      if (exportFile) {
        window.location.assign(res.result.url);
      }
    } catch (e) {}
  };

  return (
    <>
      <div className="d-flex justify-content-between progress-button mt-0 pt-5 pb-5">
        <div>
          <h2 className="pb-0 mb-0 ms-0 pt-0">Timesheet</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home{' '}
              </Link>
            </li>

            <li className="breadcrumb-item text-gray-900">Timesheet </li>
          </ul>
        </div>
        <div className="header-buttons">
          <button onClick={handleExport} className="btn btn-sm btn-outline-primary" download data-kt-element="mode" data-kt-value="light">
            <span className="menu-icon" data-kt-element="icon">
              <span className="fa fa-download me-1"></span>
            </span>
            <span className="menu-title">Export</span>
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-header border-0 pt-6 d-flex flex-column">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">Timesheet</span>
          </h3>
          <div className="row pb-2 gap-3 gap-md-0">
            <div className="col-md-2">
              <Select
                options={renderProductOptions()}
                onChange={handleProductChange}
                isMulti={true}
                placeholder="Select Product"
                value={selectedProduct}
              />
            </div>
            <div className="col-md-2">
              <Select options={renderLogDate()} isMulti={false} placeholder="Month" onChange={handleLogDateChange} value={selectedMonth} />
            </div>
            <div className="col-md-2">
              <Select options={renderUserOptions()} value={currentUser} onChange={handleUserDetails} isMulti={true} placeholder="User" />
            </div>

            <div className="col-md-2">
              <button type="button" className="btn ntn-sm btn-secondary me-sm-3 me-1" onClick={handleSubmitFilter}>
                Filter
              </button>
              <button type="reset" className="btn btn-label-secondary" onClick={handleClearFilter}>
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="card-body pt-0 pb-4">
          <div className="row">
            <div className="col-5" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
              <table className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-7" id="kt_table_users">
                <thead>
                  <tr className="text-start fw-bold fs-7 text-uppercase gs-0 border">
                    {showTable?.headers?.map(
                      (item, i) =>
                        i < 2 && (
                          <th key={i} className="min-w-40px text-primary" style={{ paddingTop: '20px', paddingBottom: '19px' }}>
                            {item?.heading}
                          </th>
                        )
                    )}
                    {
                      <th className="min-w-40px text-primary" style={{ paddingTop: '20px', paddingBottom: '19px' }}>
                        Total <br />
                        <span className="text-gray-600 fw-semibold">Hours</span>
                      </th>
                    }
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold border">
                  {showTable?.records?.map((record, i) => (
                    <tr key={i}>
                      {record?.data?.map(
                        (rowData, j) =>
                          j < 3 && (
                            <td key={j} style={{ minWidth: j === 0 ? '50%' : j === 1 ? '35%' : '15%' }}>
                              {rowData.row_data}
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
                {showTable?.records?.length !== 0 && (
                  <tfoot className="border text-primary">
                    <tr>
                      <td className="fw-bold"></td>
                      <td className="fw-bold">TOTAL</td>
                      {Object.keys(totalValues).map((key, index) => (
                        <td key={index}>{totalValues[key]}</td>
                      ))}
                    </tr>
                  </tfoot>
                )}
              </table>
              {showTable?.records?.length === 0 && <div>No Records Found ...</div>}
            </div>

            <div className="col-7" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
              <div className="table-responsive">
                <table className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-2" id="kt_table_users">
                  <thead>
                    <tr className="text-start fw-bold fs-7 text-uppercase gs-0 border text-center">
                      {showTable?.headers?.map(
                        (item, i) =>
                          i > 2 && (
                            <th key={i} className="min-w-70px mw-70px text-primary" style={{ paddingTop: '20px', paddingBottom: '19px' }}>
                              {item?.date} <br />
                              {item?.day}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="text-600 fw-semibold border">
                    {showTable?.records?.map((record, i) => (
                      <tr key={i} className="text-center">
                        {record?.data?.map(
                          (rowData, j) =>
                            j > 2 && (
                              <td
                                key={j}
                                className="min-w-70px mw-70px"
                                onClick={() => {
                                  handleViewLog(record.data[0].project_id, record.data[1].user_id, rowData.date);
                                }}
                              >
                                {rowData.row_data}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                  {showTable?.records?.length !== 0 && (
                    <tfoot className="border">
                      <tr className="text-center">
                        {Object.keys(totalValuesDays).map((key, index) => (
                          <td key={index} className="min-w-70px mw-70px text-primary">
                            {totalValuesDays[key]}
                          </td>
                        ))}
                      </tr>
                    </tfoot>
                  )}
                </table>
                {/* {showTable?.records?.length === 0 && <div>No Records Found ...</div>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimesheetTable;
