import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import AppContext from '../../../Context/AppContext';
import Flatpickr from 'react-flatpickr';
import moment from 'moment/moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import { editProduct } from '../../../services/sujata/products';

function EditProductModal({ show, onHide, success, setSuccess, productDetails = {} }) {
  const { masterData, allTemplateData } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      template:
        productDetails?.template_id && productDetails?.template_name
          ? { label: productDetails.template_name, value: productDetails.template_id }
          : '',
      product_name: productDetails?.name ?? '',
      product_type: productDetails?.type && productDetails?.type_id ? { label: productDetails.type, value: productDetails.type_id } : '',
      duration: productDetails?.duration ?? '',
      start_date: productDetails?.start_date ?? '',
      end_date: productDetails?.end_date ?? '',
      product_status: productDetails?.status && productDetails?.status_id ? { label: productDetails.status, value: productDetails.status_id } : '',
      product_owner:
        productDetails?.user_firstname && productDetails?.user_lastname && productDetails?.owner_id
          ? { label: `${productDetails?.user_firstname} ${productDetails?.user_lastname}`, value: productDetails?.owner_id }
          : {},
      product_member:
        productDetails?.productMember?.length > 0
          ? Object.keys(productDetails?.productMember).map((key) => {
              return {
                label: `${productDetails?.productMember[key]?.first_name} ${productDetails?.productMember[key]?.last_name}`,
                value: productDetails?.productMember[key]?.id,
              };
            })
          : [],
    },
  });

  function addMonths(startDate, numberOfMonths) {
    const newDate = moment(startDate, 'DD/MM/YYYY').add(numberOfMonths, 'months').format('DD/MM/YYYY');
    setValue('end_date', newDate);
  }

  function setDurationBasedOnEndDate(startDate, endDate) {
    const duration = moment
      .duration(moment(endDate, 'DD/MM/YYYY').diff(moment(startDate, 'DD/MM/YYYY')))
      .asMonths()
      .toFixed(0);
    setValue('duration', duration);
  }

  const renderAllUsers = () => {
    // if (type === "members") {
    return masterData?.users_all?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const renderAllTemplates = () => {
    if (Array.isArray(allTemplateData)) {
      return allTemplateData?.map((data) => {
        return {
          label: `${data?.name}`,
          value: data?.id,
        };
      });
    }
  };

  const renderAllStatuses = () => {
    return masterData?.product_statuses?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const renderAllTypes = () => {
    return masterData?.product_types?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const handleFormSubmit = handleSubmit((data) => {
    const button = document.getElementById('saveButton');
    button.disabled = true;
    const selectedProductMember = data?.product_member?.map((option) => option?.value);
    const selectedProductOwner = data?.product_owner?.value;
    data = {
      ...data,
      product_status: data?.product_status?.value,
      product_type: data?.product_type?.value,
      product_member: selectedProductMember,
      product_owner: selectedProductOwner,
      duration: parseInt(data?.duration),
      template_id: data?.template?.value,
    };
    editProduct(productDetails.id, data)
      .then((data) => {
        button.disabled = false;
        if (data?.success === true) {
          window.toastr.success('Product updated successfully');
          onHide();
          reset({
            // template: '',
            productName: '',
            product_type: '',
            duration: '',
            start_date: '',
            end_date: '',
            product_status: '',
            product_owner: {},
            product_member: [],
          });
          setSuccess(!success);
        } else {
          if (data?.error) {
            let errors = data?.error?.map((key) => {
              return key.message;
            });
            window.toastr.error(errors);
          }
          if (data?.success === false) {
            window.toastr.error(data?.message ?? 'Could not update product');
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // Handle form submission here
  });

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit product details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit} className="form" action="#">
          <div className="row">
            <div className="col-md-6 fv-row  mb-4">
              <label className="fs-6 fw-semibold form-label">Select template</label>
              <Controller
                name="template"
                control={control}
                // rules={{ required: 'Required' }}
                render={({ field }) => <Select {...field} options={renderAllTemplates()} isDisabled />}
              />
            </div>

            <div className="col-md-6 fv-row mb-4">
              <label className="required fs-6 fw-semibold form-label">Product name</label>
              <input
                type="text"
                className="form-control"
                {...register('product_name', {
                  required: 'Required',
                  validate: (value) => {
                    if (value.trim() === '') {
                      return 'Required';
                    }
                    if (value.trim().length < 3) {
                      return "Input can't be less than 3 characters";
                    }
                    if (value.trim().length > 100) {
                      return "Input can't be more then 100 characters";
                    }
                    return true;
                  },
                })}
              />
              <small className="text-danger">{errors?.product_name && errors.product_name.message}</small>
            </div>

            <div className="col-md-6 fv-row">
              <label className="required fs-6 fw-semibold form-label">Type</label>
              <Controller
                name="product_type"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => <Select {...field} options={renderAllTypes()} />}
              />
              <small className="text-danger">{errors?.product_type && errors.product_type.message}</small>
            </div>

            <div className="col-md-6 fv-row mb-4">
              <OverlayTrigger placement={'top'} overlay={<Tooltip>Possible values: 0 to 100 </Tooltip>}>
                <label className="form-label required ">
                  Duration (months)<i className="fas fa-exclamation-circle ms-1 fs-6"></i>
                </label>
              </OverlayTrigger>
              <input
                min={0}
                max={100}
                className="form-control"
                type="number"
                name="duration"
                placeholder=""
                {...register('duration', {
                  required: 'Required',
                  min: { value: 0, message: 'Minimum value is 0' },
                  max: { value: 100, message: 'Maximum value is 100' },
                })}
                onChange={(e) => {
                  const numberOfWeeks = parseInt(e.target.value, 10) || 0;
                  if (numberOfWeeks >= 0) {
                    const start_date = getValues('start_date');
                    addMonths(start_date, numberOfWeeks);
                  }
                }}
              />
              <small className="text-danger">{errors?.duration && errors.duration.message}</small>
            </div>

            <div className="col-md-6 fv-row mb-4">
              <label className="d-flex  align-items-center fs-6 fw-semibold form-label">Start date </label>
              <Controller
                name="start_date"
                control={control}
                defaultValue=""
                render={({ field: { onChange, ...fieldProps } }) => (
                  <Flatpickr
                    value={getValues('start_date')}
                    options={{ dateFormat: 'd/m/Y' }}
                    onChange={(dates, currentdateString) => {
                      onChange(currentdateString);

                      const durationValue = getValues('duration');
                      if (parseInt(durationValue) >= 0) {
                        const start_date = currentdateString;
                        if (moment(start_date, 'DD/MM/YYYY', true).isValid()) {
                          addMonths(start_date, durationValue);
                        }
                      }
                    }}
                    className="form-control"
                  />
                )}
                rules={{ required: 'Required' }}
              />
              <small className="text-danger">{errors?.start_date && errors.start_date.message}</small>
            </div>

            <div className="col-md-6 fv-row mb-4">
              <label className="d-flex  align-items-center fs-6 fw-semibold form-label">End date </label>
              <Controller
                name="end_date"
                control={control}
                defaultValue=""
                render={({ field: { onChange, ...fieldProps } }) => (
                  <Flatpickr
                    value={getValues('end_date')}
                    options={{
                      dateFormat: 'd/m/Y',
                      minDate: getValues('start_date'),
                    }}
                    onChange={(dates, currentdateString) => {
                      onChange(currentdateString);
                      const start_date = getValues('start_date');
                      setDurationBasedOnEndDate(start_date, currentdateString);
                    }}
                    className="form-control"
                  />
                )}
                rules={{ required: 'Required' }}
              />
              <small className="text-danger">{errors?.end_date && errors.end_date.message}</small>
            </div>

            <div className="col-md-6 fv-row mb-4">
              <label className="d-flex  align-items-center fs-6 fw-semibold form-label">Status </label>
              <Controller
                name="product_status"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => <Select {...field} options={renderAllStatuses()} />}
              />
              <small className="text-danger">{errors?.product_status && errors.product_status.message}</small>
            </div>

            <div className="col-md-6 fv-row mb-4">
              <label className=" fs-6 fw-semibold form-label assign-to">Owner</label>
              <Controller
                name="product_owner"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => <Select {...field} options={renderAllUsers()} />}
              />
              <small className="text-danger">{errors?.product_owner && errors.product_owner.message}</small>
            </div>

            <div className="col-md-12 fv-row">
              <label className=" fs-6 fw-semibold form-label assign-to">Members</label>
              <Controller
                name="product_member"
                control={control}
                rules={{ required: 'Required' }}
                render={({ field }) => <Select {...field} isMulti options={renderAllUsers()} />}
              />
              <small className="text-danger">{errors?.product_member && errors.product_member.message}</small>
            </div>
          </div>

          <div className="text-left pt-5 mt-5">
            <button type="submit" id="saveButton" className="btn btn-sm btn-secondary">
              <span className="indicator-label">Submit</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button type="reset" className="btn btn-sm btn-light ms-3" onClick={onHide}>
              Cancel
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditProductModal;
