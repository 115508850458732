import { Link } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TasksList({ taskList }) {
  const badgeColor = {
    'To do': 'light-danger',
    'In progress': 'light-warning',
    Completed: 'light-success',
  };

  const milestoneMap = {
    1: 'To do',
    2: 'In progress',
    3: 'Completed',
  };

  return (
    <>
      <tr className="sub-level">
        <td colSpan={6} className="p-0">
          <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table" style={{ display: 'table' }}>
            <thead>
              <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-100px">Task ID</th>
                <th className="min-w-175px">Title</th>
                <th className="min-w-100px">Start Date</th>
                <th className="min-w-100px">End Date</th>
                <th className="min-w-100px">Assignees</th>
                <th className="min-w-100px">Status</th>
                <th className="min-w-100px">Actions</th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              {taskList?.length === 0 ? (
                <tr>
                  <td colSpan={7} className="text-center fw-bolder">
                    No Records Found!
                  </td>
                </tr>
              ) : (
                <>
                  {taskList.map((task) => {
                    const { id, task_id, title, start_date, end_date, AssignedUser, status } = task;
                    return (
                      <tr key={id}>
                        <td>
                          <Link to={`/task/${task_id}`} className="text-gray-800 text-hover-primary fw-bold">
                            {task_id}
                          </Link>
                        </td>
                        <td>
                          <OverlayTrigger placement="top" delay={{ show: 250, hide: 300 }} overlay={<Tooltip id="button-tooltip">{title}</Tooltip>}>
                            <div style={{ width: '150px' }} className="text-gray-800 text-hover-primary fw-bold text-truncate">
                              {title}
                            </div>
                          </OverlayTrigger>
                        </td>
                        <td>{start_date}</td>
                        <td>{end_date}</td>
                        <td>
                          <div className="symbol-group symbol-hover mb-1">
                            {AssignedUser?.map((assignee, index) => {
                              if (index < 5) {
                                return (
                                  <div className="symbol symbol-circle symbol-25px" key={index}>
                                    {assignee?.image_url ? (
                                      <img alt="Pic" src={assignee?.image_url} />
                                    ) : (
                                      <span className="symbol-label bg-danger text-inverse-danger fw-bold">{assignee?.first_name?.[0] ?? ''}</span>
                                    )}
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                            {AssignedUser?.length > 5 ? (
                              <div className="symbol symbol-circle symbol-25px">
                                <span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">+ {AssignedUser?.length - 5}</span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className={`badge badge-${badgeColor[milestoneMap[status]]} fw-bold`}>{milestoneMap[status]}</div>
                        </td>
                        <td>
                          <Link to={`/task/${task_id}`}>
                            <i className="fa fa-eye "></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
}

export default TasksList;
