import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../Auth/Middleware/AuthContext';
// import localStorageStore from "../../utils/localStorageStore";
// import Role from "../../utils/role"
import { useMsal } from '@azure/msal-react';
import { clearStorage } from '../../utils/storageUtils';

const Sidebar = () => {
  const authContext = useContext(AuthContext);
  // const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('token');
    // localStorage.removeItem("attending");
    localStorage.removeItem('template');
    authContext.loginAuth(false);
    // navigate("/login");
    handleLogoutRedirect();
  };

  const handleLogoutRedirect = () => {
    let account = instance.getActiveAccount();
    clearStorage(account);

    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });
  };

  // const decryptRole = localStorageStore.getRole();

  return (
    <>
      <div>
        <div id="sidenav-overlay"></div>
        <div className="sidebar p-2 py-md-3 sidebar-mini">
          <div className="container-fluid">
            <div className="title-text d-flex align-items-center mb-4 mt-1">
              <h2 className="sidebar-title mb-0 flex-grow-1 fs-1">
                <span className="sm-txt logo-collapsed">
                  <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/logo.png`} alt="collapsed-logo" width="50px" />
                </span>
                <span className="large-logo">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/sujata.png`}
                    alt="light-logo"
                    width="130px"
                    className="ms-1 theme-light-show"
                  />
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/sujata.png`}
                    alt="dark-logo"
                    width="130px"
                    className="ms-1 theme-dark-show"
                  />
                </span>
              </h2>
            </div>
            <div className="main-menu flex-grow-1">
              <ul className="menu-list">
                <li>
                  <Link className="m-link active" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="currentColor" viewBox="0 0 16 16">
                      <path
                        fillRule="evenodd"
                        d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                      ></path>
                      <path
                        className="fill-secondary"
                        fillRule="evenodd"
                        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                      ></path>
                    </svg>
                    <span className="ms-2">Home</span>
                    <span className="highlight">Home</span>
                  </Link>
                </li>
                <li>
                  <Link className="m-link active" to="/product-list">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="currentColor" viewBox="0 0 16 16">
                      <path
                        className="fill-secondary"
                        d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                      ></path>
                      <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"></path>
                      <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"></path>
                    </svg>
                    <span className="ms-2">Products</span>
                    <span className="highlight">Products</span>
                  </Link>
                </li>
                <li>
                  <Link className="m-link active" to="/tasks">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" fill="currentColor" viewBox="0 0 31 31">
                      <path
                        stroke="#d34d04"
                        strokeWidth="0.2"
                        className="fill-secondary"
                        d="M28,10H12a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V12A2,2,0,0,0,28,10ZM12,28V12H28V28Z"
                      ></path>
                      <path
                        stroke="#000"
                        strokeWidth="0.4"
                        d="M7,20H4V4H20V7a1,1,0,0,0,2,0V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4V20a2,2,0,0,0,2,2H7a1,1,0,0,0,0-2Z"
                      ></path>
                    </svg>
                    <span className="ms-2">Tasks</span>
                    <span className="highlight">Tasks</span>
                  </Link>
                </li>
                <li>
                  <Link className="m-link" to="/kanban">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 24" width="23">
                      <path d="M7,10H9A1,1,0,0,0,9,8H7a1,1,0,0,0,0,2Z" className="fill-secondary" strokeWidth="0.5"></path>
                      <path d="M 17 4 H 11 V 3 a 1 1 0 0 0 -2 0 V 4 H 3 A 1 1 0 0 0 2 5 V 17 a 3 3 0 0 0 1 1 H 9 l -3 2 a 1 1 0 0 0 0 1 a 1 1 0 0 0 1 0 L 10 18 V 21 a 1 1 0 0 0 1 0 V 18 l 2 3 a 1 1 0 0 0 1 0 a 1 1 0 0 0 0 -1 L 12 18 H 17 a 3 3 0 0 0 1 -1 V 5 A 1 1 0 0 0 17 4 Z M 17 17 H 3 V 5 H 17 Z"></path>
                      <path d="M7,14h6a1,1,0,0,0,0-2H7a1,1,0,0,0,0,2Z" className="fill-secondary" strokeWidth="0.1"></path>
                    </svg>
                    <span className="ms-2">Kanban</span>
                    <span className="highlight">Kanban</span>
                  </Link>
                </li>
                <li>
                  <Link className="m-link" to="/timesheet">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 28 28" fill="currentColor" width="20">
                      <path d="M14.5 27C7.607 27 2 21.393 2 14.5c0-3.291 1.334-6.512 3.661-8.839a.999.999 0 1 1 1.414 1.414A10.576 10.576 0 0 0 4 14.5C4 20.29 8.71 25 14.5 25S25 20.29 25 14.5c0-5.453-4.177-9.948-9.5-10.453V9a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1C21.393 2 27 7.607 27 14.5S21.393 27 14.5 27z"></path>
                      <path
                        d="M8.793 10.207a.999.999 0 1 1 1.414-1.414l5.707 4.293a2 2 0 1 1-2.828 2.828l-4.293-5.707z"
                        className="currentColor"
                      ></path>
                    </svg>
                    <span className="ms-2">Timesheet</span>
                    <span className="highlight">Timesheet</span>
                  </Link>
                </li>
              </ul>
            </div>

            <ul className="menu-list nav navbar-nav flex-row text-center">
              <li className="nav-item flex-fill p-2">
                <div className="d-inline-block w-100 color-400" role="button" onClick={(event) => handleLogout(event)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="currentColor" viewBox="0 0 16 16">
                    <path stroke="#000" strokeWidth="0.4" d="M7.5 1v7h1V1h-1z" />
                    <path
                      stroke="#d34d04"
                      strokeWidth="0.7"
                      className="fill-secondary"
                      d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"
                    />
                  </svg>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
