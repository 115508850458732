// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#commentLabel button {
    background-color: rgb(85 85 85 / 8%);
    border-radius: 20px;
    width: 32px;
  }
  
  .errorDanger {
    color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Sujata/TaskDetails/TaskForm.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,UAAU;EACZ","sourcesContent":["#commentLabel button {\n    background-color: rgb(85 85 85 / 8%);\n    border-radius: 20px;\n    width: 32px;\n  }\n  \n  .errorDanger {\n    color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
