import { axios } from "../../utils/axios";
const baseUrl = process.env.REACT_APP_API_HOST;

export const updateTaskMilestone = (id, data) => {
    try{
      return fetch(`${baseUrl}/kanban/tasks/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(data),
      }).then((res) => res.json());
    } catch(error)
    {
      console.log(error);
    }
  }
  
  export const getProjectList = async() =>{
    try{
      return fetch(`${baseUrl}/kanban/projects-list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((res) => res.json());
    }
    catch(error)
    {
      console.log(error);
    }
  }
  
  export const filterWithGetAllTasks = async(postData) =>{
    let jsonData;
    try {
      let res = await axios.post(`${baseUrl}/kanban/filter`, postData);
      if (res?.success) {
        window.toastr.success(res.message);
      }
      jsonData = res;
    } catch (e) {
      if (e?.response) {
        jsonData = e.response.data;
  
        window.toastr.error(jsonData.message);
      } else {
        jsonData = {};
      }
    }
    return jsonData;
  }