import { useContext } from 'react';
import Select from 'react-select';
import TaskContext from '../../../Context/Chartcontext';
import { handleSizeChange } from '../../../utils/custom';
import Flatpickr from 'react-flatpickr';

function TaskInfo({
  clientData,
  masterData,
  enabled,
  getdata,
  data,
  handleDateTimeChange,
  handleSelectChange,
  errorsForChat,
  mainData,
  handleKeypress,
  errorsFromAuditorToChart,
  setIsTouched,
  dataSuccessfullyAdded,
  configurationProvider,
  configurationConstants,
  setAssignees,
  assignees,
}) {
  const { setMilestone, milestone, inpval, epic, setEpic, priority, setPriority } = useContext(TaskContext);
  //   const [projectMembers, setProjectMembers] = useState([]);
  // const [count, setCount] = useState(false);
  // const task_id = data?.Id;

  const renderPriorityList = () => {
    return masterData?.priorities?.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  };
  const renderEpicList = () => {
    return clientData?.groups?.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  };

  const renderMilestoneList = () => {
    return masterData?.milestones?.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  };

  const renderProjectMembersList = () => {
    return clientData?.product?.members?.map((member) => ({
      label: `${member.first_name} ${member.last_name}`,
      value: member.id,
    }));
  };

  const handleEpicChange = (event) => {
    setEpic({
      label: event?.label,
      value: event?.value,
    });
  };

  const handleMilestoneChange = (event) => {
    setMilestone(event);
  };

  const handlePriorityChange = (event) => {
    setPriority({
      label: event.label,
      value: event.value,
    });
  };

  const handleTaskAssigneeChange = (event) => {
    const assignedUsers = [];
    event.forEach((assignedUser) => {
      assignedUsers.push({
        label: `${assignedUser.label}`,
        value: assignedUser.value,
      });
    });
    setAssignees(assignedUsers);
  };

  return (
    <div className="card card-flush mb-6 mb-xl-9 card1">
      <div className="card-header mt-6 minimize">
        <div className="card-title flex-column">
          <h2 className="mb-1">Task Info</h2>
          <div className="fs-6 fw-semibold text-muted">All relevant task fields</div>
        </div>
        <div className="card-toolbar mt-0">
          <button className="btn btn-icon btn-sm btn-light-primary justify-content-center">
            <i className="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <div id="chart_info" className="card-body flex-column">
        <div className="d-flex flex-column gap-5 gap-md-7 mb-5">
          <div className="row">
          <div className="col-md-4 fv-row mb-5">
              <label className="required fs-6 fw-semibold form-label mb-2 assign-to">Task Name</label>
              <input className='form-control' name='name' type='text'
              value={inpval?.name ?? ''} onChange={getdata} required placeholder='Name' minLength={3} maxLength={100} />
            </div>
            <div className="col-md-2 fv-row mb-5">
              <label className="required fs-6 fw-semibold form-label mb-2 assign-to">Priority</label>
              <Select
                id="priority"
                name="priorityId"
                menuPlacement={'auto'}
                isSearchable={true}
                options={renderPriorityList()}
                isMulti={false}
                value={priority}
                defaultValue={{ label: '', value: 1 }}
                onChange={handlePriorityChange}
              />
            </div>
            <div className="col-md-4 fv-row mb-5">
              <label className="fs-6 fw-semibold form-label mb-2 assign-to">Group</label>
              <Select
                id="epic"
                name="EpicId"
                menuPlacement={'auto'}
                isSearchable={true}
                options={renderEpicList()}
                isMulti={false}
                isClearable={true}
                value={epic}
                defaultValue={{ label: '', value: 1 }}
                onChange={handleEpicChange}
              />
            </div>
            <div className="col-md-2 fv-row mb-5">
              <label className="required fs-6 fw-semibold form-label mb-2 assign-to">Status</label>
              <Select
                id="milestone"
                name="MilestoneId"
                menuPlacement={'auto'}
                isSearchable={true}
                options={renderMilestoneList()}
                isMulti={false}
                value={milestone}
                defaultValue={{ label: 'Todo', value: 1 }}
                onChange={handleMilestoneChange}
              />
            </div>
            <div className="col-md-4 fv-row mb-5">
              <label className="required fs-6 fw-semibold form-label mb-2 assign-to">Assignees</label>
              <Select
                id="assignee"
                name="assignee_id"
                options={renderProjectMembersList()}
                isMulti={true}
                value={assignees}
                isDisabled={false}
                onChange={handleTaskAssigneeChange}
                formatOptionLabel={(user) => (
                  <div value={user?.value}>
                    <span className="fw-semibold">{user.value ? user?.label ?? '' : 'Unassigned'}</span>
                  </div>
                )}
              />
            </div>
            <div className="col-md-4 fv-row mb-5">
              <label className="required fs-6 fw-semibold form-label mb-2 assign-to">Start date</label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: 'd-m-Y',
                  animate: true,
                }}
                value={inpval?.start_date}
                name="start_date"
                onChange={handleDateTimeChange}
                disabled={false}
              />
            </div>
            <div className="col-md-4 fv-row mb-5">
              <label className="required fs-6 fw-semibold form-label mb-2 assign-to">End date</label>
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: 'd-m-Y',
                }}
                value={inpval?.end_date}
                name="end_date"
                onChange={handleDateTimeChange}
                disabled={false}
              />
            </div>
            <div className="col-xl-12 col-lg-12 col-12 mt-0">
              <label className="form-label">Description</label>
                <textarea
                  cols="30"
                  rows="3"
                  className="form-control"
                  name='description'
                  value={inpval?.description || ''}
                  onChange={getdata}
                  onKeyUp={handleKeypress}
                  disabled={false}
                  onFocus={handleSizeChange}
                  onBlur={handleSizeChange}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskInfo;
