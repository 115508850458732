import { useState, useEffect, useContext } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable as Droppable } from './helpers/StrictModeDroppable';
import { filterWithGetAllTasks, updateTaskMilestone } from '../../../services/sujata/kanban';
import { getGroupsListByProductId } from '../../../services/sujata/products';
import AppContext from '../../../Context/AppContext';
import Select from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const columnColor = ['#fdf8f8', '#fffcf1', '#f5fbf8'];
const columnHeadingColor = ['text-danger', 'text-warning', 'text-success'];

const onDragEnd = async (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  const task = columns[source.droppableId].items[source.index];

  if (source.droppableId !== destination.droppableId) {
    const milestoneMap = {
      todo: 1,
      in_progress: 2,
      completed: 3,
    };
    const updateData = {
      milestone_id: milestoneMap[destination.droppableId] || [],
    };
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
        count: sourceItems.length ?? 0,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
        count: destItems.length ?? 0,
      },
    });
    const response = await updateTaskMilestone(task.id, updateData);
    if (response?.success === true) {
      window.toastr.success(response?.message);
    } else if (response?.success === false) {
      window.toastr.error(response?.message);
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
          count: copiedItems.length ?? 0,
        },
      });
    }
  }
};

const KanbanBoard = () => {
  const { state } = useLocation();
  const [todoTask, setTodoTask] = useState([]);
  const [inProgressTask, setInProgressTask] = useState([]);
  const [completedTask, setCompletedTask] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [filterList, setFilterList] = useState();
  const [lastSelectedProduct, setLastSelectedProduct] = useState(state?.product_id ? [state.product_id] : []);
  const [lastSelectedGroup, setLastSelectedGroup] = useState();
  const [lastSelectedPriority, setLastSelectedPriority] = useState();

  const [currentTodoTask, setCurrentTodoTask] = useState();
  const [currentInProgressTask, setCurrentInProgressTask] = useState();
  const [currentCompletedTask, setCurrentCompletedTask] = useState();
  const [groupData, setGroupData] = useState();
  const { masterData } = useContext(AppContext);

  const fetchGroup = (product_id) => {
    getGroupsListByProductId(product_id)
      .then((data) => {
        setGroupData(data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (filterList) {
      fetchAllTasks(filterList);
    }
    //eslint-disable-next-line
  }, [filterList]);

  useEffect(() => {
    setColumns((prevColumns) => ({
      ...prevColumns,
      todo: {
        ...prevColumns.todo,
        items: todoTask || [],
        count: (todoTask || []).length,
      },
      in_progress: {
        ...prevColumns.in_progress,
        items: inProgressTask || [],
        count: (inProgressTask || []).length,
      },
      completed: {
        ...prevColumns.completed,
        items: completedTask || [],
        count: (completedTask || []).length,
      },
    }));
  }, [todoTask, inProgressTask, completedTask]);

  const handleProductChange = (selectedOption) => {
    setLastSelectedProduct(selectedOption);
    setLastSelectedGroup("");
    setLastSelectedPriority("");
    localStorage.setItem('lastSelectedProduct', JSON.stringify(selectedOption));
    fetchGroup(selectedOption?.value);
    const productId = selectedOption?.value;
    setFilterList({product_id: productId});
  };

  useEffect(() => {
    const storedSelectedProduct =
      localStorage.getItem('lastSelectedProduct') !== 'undefined' ? JSON.parse(localStorage.getItem('lastSelectedProduct')) : null;
    if (storedSelectedProduct) {
      lastSelectedProduct.length === 0 ? handleProductChange(storedSelectedProduct) : handleProductChange(state?.product_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProductOptions = () => {
    return masterData?.product_names?.map((product) => ({
      label: product.name,
      value: product.id,
    }));
  };

  const renderGroupOptions = () => {
    return groupData?.map((group) => ({
      label: group.name,
      value: group.id,
    }));
  };

  const handleGroupChange = (selectedOption) => {
    setLastSelectedGroup(selectedOption);
    const groupId = selectedOption?.value;
    setFilterList((previous) => ({ ...previous, group_id: groupId }));
  };

  const renderPriorityOptions = () => {
    return masterData?.priorities?.map((priority) => ({
      label: priority.name,
      value: priority.id,
    }));
  };

  const handlePriorityChange = (selectedOption) => {
    setLastSelectedPriority(selectedOption);
    const priority = selectedOption?.value;
    setFilterList((previous) => ({ ...previous, priority: priority }));
  };

  const fetchAllTasks = async (filterList) => {
    filterWithGetAllTasks(filterList)
      .then((response) => {
        handleAllTasks(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleAllTasks(response) {
    setTodoTask(response?.data?.todo);
    setInProgressTask(response?.data?.in_progress);
    setCompletedTask(response?.data?.completed);

    setCurrentTodoTask(response?.data?.todo);
    setCurrentInProgressTask(response?.data?.in_progress);
    setCurrentCompletedTask(response?.data?.completed);
  }

  const taskStatus = {
    todo: {
      name: 'To Do',
      items: todoTask || [],
      id: 1,
      count: (todoTask || []).length ?? 0,
    },
    in_progress: {
      name: 'In Progress',
      items: inProgressTask || [],
      id: 2,
      count: (inProgressTask || []).length ?? 0,
    },
    completed: {
      name: 'Completed',
      items: completedTask || [],
      id: 3,
      count: (completedTask || []).length ?? 0,
    },
  };

  const [columns, setColumns] = useState(taskStatus);

  const handleFilterChange = (e) => {
    const inputValue = e.target.value.toLowerCase(); // Convert input value to lowercase for case-insensitive matching
    // Filter tasks in each column based on the input value
    const filteredTodo = currentTodoTask ? currentTodoTask.filter((task) => task?.name?.toLowerCase().includes(inputValue)) : [];
    const filteredInProgress = currentInProgressTask ? currentInProgressTask.filter((task) => task?.name?.toLowerCase().includes(inputValue)) : [];
    const filteredCompleted = currentCompletedTask ? currentCompletedTask.filter((task) => task?.name?.toLowerCase().includes(inputValue)) : [];

    // Update the state for each column with the filtered tasks or reset to original when inputValue is empty
    setTodoTask(inputValue === '' ? currentTodoTask : filteredTodo);
    setInProgressTask(inputValue === '' ? currentInProgressTask : filteredInProgress);
    setCompletedTask(inputValue === '' ? currentCompletedTask : filteredCompleted);

    // Update the filterValue state with the current input value
    setFilterValue(inputValue);
  };

  return (
    <>
      <div className="justify-content-between progress-button mt-5">
        <h2 className="pb-0 mb-0 ms-0 pt-0">Kanban Board</h2>
        <ul className="breadcrumb fw-semibold fs-base mb-1">
          <li className="breadcrumb-item text-muted">
            <Link to={`/`} className="text-muted text-hover-primary">
              Home{' '}
            </Link>
          </li>

          <li className="breadcrumb-item text-gray-900">Kanban Board </li>
        </ul>
      </div>

      <div className="row mb-4">
        <div className="col-lg-2 px-5 mt-5">
          <div className="input-group">
            <input type="text" className="form-control" placeholder="Search by task name..." value={filterValue} onChange={handleFilterChange} />
          </div>
        </div>
        <div className="col-lg-6 ms-auto">
          <div className="row justify-content-end">
            <div className="col-lg-3">
              <Select
                className="mt-7"
                options={renderProductOptions()}
                value={lastSelectedProduct}
                onChange={handleProductChange}
                isMulti={false}
                placeholder="Select Products"
              />
            </div>
            <div className="col-lg-3">
              <Select
                className="mt-7"
                options={renderGroupOptions()}
                value={lastSelectedGroup}
                onChange={handleGroupChange}
                isMulti={false}
                placeholder="Select group"
                isClearable={true}
              />
            </div>
            <div className="col-lg-3">
              <Select
                className="mt-7"
                options={renderPriorityOptions()}
                value={lastSelectedPriority}
                onChange={handlePriorityChange}
                isMulti={false}
                placeholder="Priority"
                isClearable={true}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="kanban-container">
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  key={columnId}
                >
                  <div className="px-5 py-5 rounded ms-5 kanban-board-header light-primary" style={{ backgroundColor: columnColor[index] }}>
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <>
                            <header className={`kanban-board-header fs-3 ${columnHeadingColor[index]}`}>
                              <div className={`kanban-title-board`}>{column?.name}</div>
                            </header>
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              data-id="_fixed_height"
                              className="kanban-board custom_scroll overflow-auto"
                              style={{
                                width: '380px',
                                height: '620px',
                                padding: 16,
                                backgroundColor: columnColor[index],
                              }}
                            >
                              {column.items?.map((item, index) => {
                                return (
                                  <Draggable key={item.id} draggableId={item?.id.toString()} index={index}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          className={`kanban-item ${snapshot.isDragging ? 'dragging' : ''} shadow-sm bg-white p-4 mb-5 rounded`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div className="flex align-items-center w-100">
                                            <div className="d-flex flex-column align-items-start">
                                              <OverlayTrigger overlay={<Tooltip>{'Go to Task'}</Tooltip>} key={item?.id} placement={'top'}>
                                                <div
                                                  className="d-flex mb-1 text-primary"
                                                  onClick={() => {
                                                    localStorage.setItem('key', item.id);
                                                  }}
                                                >
                                                  {' '}
                                                  <Link to={`/task/${item?.unique_task_no}`} className="fw-bold fs-4 hover">
                                                    #{item?.unique_task_no}
                                                  </Link>
                                                </div>
                                              </OverlayTrigger>
                                              <div className="d-flex flex-column align-items-start border-bottom pb-3 w-100">
                                                <span className="text-gray-900-50 pt-2 mb-1" style={{ wordBreak: 'break-all' }}>
                                                  {item?.name}
                                                </span>
                                              </div>
                                              <div className="d-flex justify-content-between align-items-start pt-3 w-100">
                                                <span className="badge badge-light-info">{item?.group_name}</span>
                                                <div className="d-flex">
                                                  <div className="task-assignee me-2 pt-1">
                                                    <div className="symbol-group symbol-hover">
                                                      {item?.AssignedUser?.map((assignee, index) => {
                                                        if (index < 5) {
                                                          return (
                                                            <div className="symbol symbol-circle symbol-25px" key={index}>
                                                              {assignee?.image_url ? (
                                                                <img alt="Pic" src={assignee?.image_url} />
                                                              ) : (
                                                                <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                                                                  {assignee?.first_name?.[0] ?? ''}
                                                                </span>
                                                              )}
                                                            </div>
                                                          );
                                                        } else {
                                                          return null;
                                                        }
                                                      })}
                                                      {item?.AssignedUser?.length > 5 ? (
                                                        <div className="symbol symbol-circle symbol-25px">
                                                          <span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">
                                                            + {item?.AssignedUser?.length - 5}
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="symbol-group symbol-hover flex-nowrap justify-content-end">
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {item?.assignee_id ? `${item?.assigneeFirstName} ${item?.assigneeLastName}` : 'Unassigned'}
                                                </Tooltip>
                                              }
                                              key={item?.id}
                                              placement={'left'}
                                            >
                                              <div className="symbol symbol-35px symbol-circle user-image" data-kt-initialized="1">
                                                {/* <img alt="name" src={item?.assignee_id ? item?.assignee_image_url ?? `${process.env.PUBLIC_URL}/assets/media/avatars/300-${imageIdGenerate(item?.assignee_id)}.jpg` : `${process.env.PUBLIC_URL}/assets/media/avatars/blank.png`} /> */}
                                              </div>
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          </>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
    </>
  );
};
export default KanbanBoard;
