import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getMasterData } from '../../../services/sujata/products';
import { getAllGroupsAndTasks, getTasksByFilter } from '../../../services/sujata/tasks';
import CustomPagination from '../../Manager/ManagerWorkLists/CustomPagination';
import AddTaskModal from '../TaskDetails/AddTaskModal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const badgeColor = {
  'To do': 'light-danger',
  'In progress': 'light-warning',
  Completed: 'light-success',
};

function TasksList() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [masterData, setMasterData] = useState();
  const [selectedProduct, setSelectedProduct] = useState(state?.product_id ? [state.product_id] : []);
  const [selectedMilestone, setSelectedMilestone] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedTaskName, setSelectedTaskName] = useState('');
  const [selectedProductMembers, setSelectedProductMembers] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tasks, setTasks] = useState();
  const [filterObj, setFilterObj] = useState({});
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [groupsId, setGroupsId] = useState([]);
  const [allTasks, setAllTasks] = useState({});
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [taskAddedSuccess, setTaskAddedSuccess] = useState(false);
  const [revalidate, setRevalidate] = useState(false);
  const [startKey, setStartKey] = useState(0);
  const [endKey, setEndKey] = useState(0);
  const size = 5;

  const closeTaskModal = ({ reOpen = false }) => {
    setShowAddTaskModal(false);
    if (reOpen) {
      setTimeout(() => {
        setShowAddTaskModal(true);
      }, 200);
    }
  };

  useEffect(() => {
    getMasterData()
      .then((res) => {
        if (res.success) {
          setMasterData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    getAllGroupsAndTasks().then((res) => {
      if (res.success) {
        setGroupsId(res?.data?.uniqueGroupId);
        setAllTasks({
          name: res?.data?.uniqueTaskName,
          code: res?.data?.uniqueTaskNo,
        });
      }
    });
  }, []);

  useEffect(() => {
    let flag = true;
    const reqObj = { ...filterObj };
    if (state?.product_id?.value && Object.keys(filterObj).length === 0) {
      reqObj.product_id = [state.product_id.value];
    }
    navigate('/tasks', { replace: true, state: null });

    getTasksByFilter(reqObj, currentPage, size)
      .then((res) => {
        if (res.success && flag) {
          setTasks(res?.data?.taskRecords);
          setTotalCount(res?.data?.taskCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      flag = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, size, filterObj, taskAddedSuccess, revalidate]);

  const handleFilter = (e) => {
    e.target.blur();
    const assignees = selectedProductMembers.map((assignee) => {
      return assignee.value;
    });
    const product = selectedProduct.map((product) => {
      return product.value;
    });
    const milestones = selectedMilestone.map((milestone) => {
      return milestone.value;
    });

    let groupName;
    if (selectedGroup?.value) {
      groupName = selectedGroup?.value.split('(')[0]?.trim();
    }

    setFilterObj({
      assignee_id: assignees,
      product_id: product,
      task_no: selectedTaskId?.value,
      task_name: selectedTaskName?.value,
      milestone_id: milestones,
      group_name: groupName,
      start_date: startDate,
      end_date: endDate,
    });
    setCurrentPage(1);
  };

  const handleClear = () => {
    setSelectedGroup('');
    setSelectedMilestone([]);
    setSelectedProduct([]);
    setSelectedProductMembers([]);
    setSelectedTaskId('');
    setSelectedTaskName('');
    setStartDate('');
    setEndDate('');
    setStartKey((prevKey) => prevKey + 1);
    setEndKey((prevKey) => prevKey + 1);
    setFilterObj({});
    setRevalidate((revalidate) => !revalidate);
  };

  const handleProductChange = (e) => {
    setSelectedProduct(e);
  };

  const handleMilestoneChange = (e) => {
    setSelectedMilestone(e);
  };

  const handleTaskIdChange = (e) => {
    setSelectedTaskId(e);
  };

  const handleTaskNameChange = (e) => {
    setSelectedTaskName(e);
  };

  const handleGroupChange = (e) => {
    setSelectedGroup(e);
  };

  const handleProductMemberChange = (e) => {
    setSelectedProductMembers(e);
  };

  const handleStartDate = (event, picker) => {
    picker.element.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    setStartDate(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
  };

  const handleEndDate = (event, picker) => {
    picker.element.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    setEndDate(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
  };

  const handleCancelStartDate = (event, picker) => {
    picker.element.val('');
    setStartDate('');
    setStartKey((prevKey) => prevKey + 1);
  };
  const handleCancelEndDate = (event, picker) => {
    picker.element.val('');
    setEndDate('');
    setEndKey((prevKey) => prevKey + 1);
  };

  const renderProductList = () => {
    return masterData?.product_names?.map((product) => {
      return {
        label: product?.name,
        value: product?.id,
      };
    });
  };

  const renderMilestones = () => {
    return masterData?.milestones?.map((milestone) => {
      return {
        label: milestone?.name,
        value: milestone?.id,
      };
    });
  };

  const renderProductMembers = () => {
    return masterData?.users_all?.map((member) => {
      return {
        label: member?.name,
        value: member?.id,
      };
    });
  };

  const renderGroupsId = () => {
    return groupsId?.map((groupId) => {
      return {
        label: groupId,
        value: groupId,
      };
    });
  };

  const renderTaskId = () => {
    return allTasks?.code?.map((taskId) => {
      return {
        label: taskId,
        value: taskId,
      };
    });
  };

  const renderTaskNames = () => {
    return allTasks?.name?.map((task) => {
      return {
        label: task,
        value: task,
      };
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start flex-wrap mb-0 mt-4">
        <div>
          <h2>Tasks</h2>
          <ul className="breadcrumb fw-semibold fs-base mb-1">
            <li className="breadcrumb-item text-muted">
              <Link to="/" className="text-muted text-hover-primary">
                Home{' '}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-900">Tasks</li>
          </ul>
        </div>
        <div>
          <button type="button" className="btn btn-sm btn-primary" onClick={() => setShowAddTaskModal(true)}>
            <i className="fa fa-plus fa-plus me-1"></i>Add
          </button>
        </div>
      </div>
      <div className="card priority-card mt-8">
        <div className="card-header d-block pt-3">
          <h5 className="card-title mb-3">Search Filter</h5>
          <div className="row pb-2 g-3 ">
            <div className="col-md-1">
              <Select
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={renderTaskId()}
                value={selectedTaskId}
                onChange={handleTaskIdChange}
                placeholder="Task ID"
              />
            </div>
            <div className="col-md-1">
              <Select
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={renderTaskNames()}
                value={selectedTaskName}
                onChange={handleTaskNameChange}
                placeholder="Title"
              />
            </div>
            <div className="col-md-2">
              <Select options={renderProductList()} value={selectedProduct} isMulti={true} onChange={handleProductChange} placeholder="Products" />
            </div>
            <div className="col-md-1">
              <Select
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={renderGroupsId()}
                value={selectedGroup}
                onChange={handleGroupChange}
                placeholder="Group"
              />
            </div>
            <div className="col-md-2">
              <DateRangePicker
                key={startKey}
                initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear', format: 'DD/MM/YYYY' }, showDropdowns: true }}
                onApply={handleStartDate}
                style={{ zIndex: '20' }}
                onCancel={handleCancelStartDate}
              >
                <input type="text" placeholder="Start Date" autoComplete="off" className="form-control" value={startDate} />
              </DateRangePicker>
            </div>
            <div className="col-md-2">
              <DateRangePicker
                key={endKey}
                initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear', format: 'DD/MM/YYYY' }, showDropdowns: true }}
                onApply={handleEndDate}
                style={{ zIndex: '20' }}
                onCancel={handleCancelEndDate}
              >
                <input type="text" value={endDate} className="form-control" placeholder="End Date" autoComplete="off" />
              </DateRangePicker>
            </div>
            <div className="col-md-2">
              <Select
                options={renderProductMembers()}
                isMulti={true}
                value={selectedProductMembers}
                onChange={handleProductMemberChange}
                placeholder="Assignees"
              />
            </div>
            <div className="col-md-2">
              <Select options={renderMilestones()} isMulti={true} onChange={handleMilestoneChange} value={selectedMilestone} placeholder="Status" />
            </div>
            <div className="col-md-2">
              <button onClick={handleFilter} type="button" className="btn ntn-sm btn-secondary me-sm-3 me-1">
                Filter
              </button>
              <button onClick={handleClear} type="reset" className="btn btn-label-secondary">
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="card-body p-4 py-4">
          <div className="table-responsive">
            <table className="table data-table align-middle table-row-dashed fs-6 gy-5 gs-7" id="kt_ecommerce_sales_table">
              <thead>
                <tr className="text-start fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-100px">Task ID</th>
                  <th className="min-w-175px">Title</th>
                  <th className="min-w-100px">Product</th>
                  <th className="min-w-100px">Group</th>
                  <th className="min-w-100px">Start Date</th>
                  <th className="min-w-100px">End Date</th>
                  <th className="min-w-100px">Assignees</th>
                  <th className="min-w-100px">Status</th>
                  <th className="min-w-100px">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-semibold">
                {tasks?.map((task) => {
                  return (
                    <tr key={task?.id}>
                      <td>
                        <Link to={`/task/${task?.unique_task_no}`} className="text-gray-800 text-hover-primary fw-bold">
                          {task?.unique_task_no}
                        </Link>
                      </td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 300 }}
                          overlay={<Tooltip id="button-tooltip">{task?.name}</Tooltip>}
                        >
                          <div style={{ width: '150px' }} className="text-gray-800 text-hover-primary fw-bold text-truncate">
                            {task?.name}
                          </div>
                        </OverlayTrigger>
                      </td>
                      <td>{task?.Product}</td>
                      <td>{task?.group_name}</td>
                      <td>{task?.start_date}</td>
                      <td>{task?.end_date}</td>
                      <td>
                        <div className="symbol-group symbol-hover mb-1">
                          {task?.AssignedUser?.map((assignee, index) => {
                            if (index < 5) {
                              return (
                                <div className="symbol symbol-circle symbol-25px" key={assignee?.id}>
                                  {assignee?.image_url ? (
                                    <img alt="Pic" src={assignee?.image_url} />
                                  ) : (
                                    <span className="symbol-label bg-danger text-inverse-danger fw-bold">{assignee?.first_name?.[0] ?? ''}</span>
                                  )}
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                          {task?.AssignedUser?.length > 5 ? (
                            <div className="symbol symbol-circle symbol-25px">
                              <span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">+ {task?.AssignedUser?.length - 5}</span>
                            </div>
                          ) : null}
                        </div>
                      </td>
                      <td>
                        <div className={`badge badge-${badgeColor[task?.milestone_name]} fw-bold`}>{task?.milestone_name}</div>
                      </td>
                      <td>
                        <Link to={`/task/${task.unique_task_no}`}>
                          <i className="fa fa-eye "></i>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {totalCount === 0 ? (
              <h3 style={{ textAlign: 'center' }}>No Records Found!</h3>
            ) : (
              <CustomPagination dataPerPage={size} totalData={totalCount} paginate={paginate} currentPage={currentPage} />
            )}
          </div>
        </div>
      </div>

      {showAddTaskModal && (
        <AddTaskModal show={showAddTaskModal} onHide={closeTaskModal} success={taskAddedSuccess} setSuccess={setTaskAddedSuccess} />
      )}
    </>
  );
}

export default TasksList;
