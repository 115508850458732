import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Link } from 'react-router-dom';

function Cards({ productDetail = null }) {
  const [series, setSeries] = useState([10, 10, 10]);

  useEffect(() => {
    if (productDetail !== null) {
      setSeries([
        productDetail?.milestoneCounts?.completedTask ?? 0,
        productDetail?.milestoneCounts?.inprogressTask ?? 0,
        productDetail?.milestoneCounts?.todoTask ?? 0,
      ]);
    }
  }, [productDetail]);

  const labels = ['Completed', 'In Progress', 'To Do'];

  return (
    <>
      <div className="col-xl-4">
        <div className="card card-flush mb-6 mb-xl-9 card1 chart_card h-100">
          <div className="card-body pt-9 pb-5">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
              <div className="flex-grow-1">
                <div className="justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-0">
                      <div className="fs-2 fw-bold me-1">
                        <h1>{productDetail?.name ?? ''}</h1>
                      </div>

                      <div className="card-toolbar">
                        <Link
                          to={`products/${productDetail?.id}`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Check product details"
                        >
                          <i className="fa-solid fa-circle-arrow-right text-primary fs-4"></i>
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2">
                      <p className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                        <span className="svg-icon svg-icon-4 me-1">
                          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              opacity="0.3"
                              d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z"
                              fill="currentColor"
                            />
                            <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor" />
                            <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor" />
                            <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor" />
                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
                          </svg>
                        </span>
                        Type: <strong className="ms-2"> {productDetail?.type ?? ''}</strong>
                      </p>
                      <p className="d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-5">
                        <span className="svg-icon svg-icon-4 me-1">
                          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z"
                              fill="currentColor"
                            ></path>
                            <path
                              opacity="0.3"
                              d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                        Duration: <strong className="ms-2">{productDetail?.duration ?? ''} Months</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-stack">
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="d-flex flex-wrap">
                      <div className=" min-w-100px pt-3 px-4 me-4">
                        <div className="d-flex align-items-center">
                          <div className="fs-5 fw-bold" data-kt-countup="false" data-kt-countup-value="75">
                            {productDetail?.start_date ?? ''}
                          </div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">Start date</div>
                      </div>

                      <div className=" min-w-100px pt-3 px-4 me-4">
                        <div className="d-flex align-items-center">
                          <div className="fs-5 fw-bold" data-kt-countup="false" data-kt-countup-value="75">
                            {productDetail?.end_date ?? ''}
                          </div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">End date</div>
                      </div>

                      <div className=" min-w-100px pt-3 px-4 me-4">
                        <div className="d-flex align-items-center">
                          <div className="fs-5 fw-bold" data-kt-countup="false">
                            {productDetail?.status ?? ''}
                          </div>
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">Status</div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="row" style={{ height: '200px' }}>
                  <div className="col-md-4">
                    <div className="pt-4">
                      <div style={{ width: '150px', height: '150px' }}>
                        <div dir="ltr" style={{ position: 'relative', width: 210, height: 210 }}>
                          <div aria-label="A chart." style={{ position: 'absolute', left: -40, top: 0, width: '100%', height: '100%' }}>
                            <Chart
                              options={{
                                labels,
                                legend: { show: false },
                                dataLabels: { enabled: false },
                                plotOptions: {
                                  pie: {
                                    donut: {
                                      labels: {
                                        show: true,
                                        name: { show: false },
                                        value: {
                                          show: false,
                                          color: 'var(--bs-text-dark)',
                                          fontFamily: 'var(--bs-font-sans-serif)',
                                          fontSize: '2rem',
                                          formatter: function (productDetail) {
                                            return productDetail?.total_tasks ?? 'Default';
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                colors: ['#5DCD89', '#F3993E', '#DC3A2F'],
                              }}
                              series={series}
                              type="donut"
                              width="210px"
                            />
                            <div
                              aria-label="A tabular representation of the data in the chart."
                              style={{ position: 'absolute', left: '-10000px', top: 'auto', width: 1, height: 1, overflow: 'hidden' }}
                            ></div>
                          </div>
                        </div>
                        <div
                          aria-hidden="true"
                          style={{
                            display: 'none',
                            position: 'absolute',
                            top: 130,
                            left: 130,
                            whiteSpace: 'nowrap',
                            fontFamily: 'Arial',
                            fontSize: 8,
                            fontWeight: 'bold',
                          }}
                        ></div>
                        <div />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8 pb-2" style={{ height: '200px', overflowY: 'scroll' }}>
                    <div className="ps-4">
                      <div className=" border-0 pt-3">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-dark">Today's tasks</span>
                        </h3>
                      </div>
                      {productDetail?.todayTask.length > 0 ? (
                        productDetail?.todayTask.map((task) => (
                          <div key={task?.id} className="py-1 d-flex flex-column today-task-list">
                            <div className="d-flex align-items-center position-relative mb-2">
                              <div className="bullet w-5px h-5px rounded-2 bg-primary me-1"></div>
                              <div className="fw-semibold ms-2">
                                <Link to={`/task/${task?.unique_task_no}`} className="fs-5 text-gray-900 text-hover-primary">
                                  {task?.name}
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="py-1 d-flex flex-column today-task-list">
                          <div className="d-flex align-items-center position-relative mb-2">
                            <div className="bullet w-5px h-5px rounded-2 bg-primary me-1"></div>
                            <div className="fw-semibold ms-2">
                              <div className="fs-5 text-gray-900 text-hover-primary">
                                <i>No tasks scheduled</i>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* <div className="py-1 d-flex flex-column today-task-list">
                        <div className="d-flex align-items-center position-relative mb-2">
                          <div className="bullet w-5px h-5px rounded-2 bg-primary me-1"></div>

                          <div className="fw-semibold ms-2">
                            <a href="task_list.html" className="fs-5 text-gray-900 text-hover-primary">
                              Lorem ipsum dolor sit
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="py-1 d-flex flex-column today-task-list">
                        <div className="d-flex align-items-center position-relative mb-2">
                          <div className="bullet w-5px h-5px rounded-2 bg-primary me-1"></div>

                          <div className="fw-semibold ms-2">
                            <a href="task_list.html" className="fs-5 text-gray-900 text-hover-primary">
                              Totam nihil nam explicesa...
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="py-1 d-flex flex-column today-task-list">
                        <div className="d-flex align-items-center position-relative mb-2">
                          <div className="bullet w-5px h-5px rounded-2 bg-primary me-1"></div>

                          <div className="fw-semibold ms-2">
                            <a href="task_list.html" className="fs-5 text-gray-900 text-hover-primary">
                              Accusantium velit porrshs...
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="py-1 d-flex flex-column today-task-list">
                        <div className="d-flex align-items-center position-relative mb-2">
                          <div className="bullet w-5px h-5px rounded-2 bg-primary me-1"></div>

                          <div className="fw-semibold ms-2">
                            <a href="task_list.html" className="fs-5 text-gray-900 text-hover-primary">
                              Eveniet tempora commod...
                            </a>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Owner</span>
                    <div
                      className="symbol symbol-35px symbol-circle"
                      data-bs-toggle="tooltip"
                      title={`${productDetail?.user_firstname} ${productDetail?.user_lastname}`}
                    >
                      {productDetail?.user_image_url ? (
                        <img alt="Pic" src={productDetail?.user_image_url} />
                      ) : (
                        <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                          {productDetail?.user_firstname[0].toUpperCase() ?? ''}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="d-flex flex-column justify-content-end pe-0">
                      <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Members</span>
                      <div className="symbol-group symbol-hover flex-nowrap">
                        {productDetail?.productMember.length > 0 &&
                          productDetail?.productMember.map((member) => (
                            <div
                              key={member.id}
                              className="symbol symbol-35px symbol-circle"
                              data-bs-toggle="tooltip"
                              title={`${member.first_name ?? ''} ${member.last_name ?? ''}`}
                            >
                              {member?.image_url ? (
                                <img alt="Pic" src={member?.image_url} />
                              ) : (
                                <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                  {member.first_name[0].toUpperCase() ?? ''}
                                </span>
                              )}
                            </div>
                          ))}

                        {/* <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Sweeti Rajput">
                          <span className="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                        </div>

                        <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Preeti Gupta">
                          <span className="symbol-label bg-danger text-inverse-danger fw-bold">P</span>
                        </div>

                        <div role="button" className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                          <span className="symbol-label fs-8 fw-bold">+1</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
