import React, { useEffect, useState } from 'react';
import { getGroupsByProductId } from '../../../../services/sujata/products';
import AddGroupModal from './AddGroupModal';
import AddTaskModal from '../../TaskDetails/AddTaskModal';
import TasksList from './TaskList';

const groupColorClasses = [
  ['bs-yellow-light', 'bg-yellow'],
  ['bg-light-info', 'bg-info'],
  ['bg-light-success', 'bg-success'],
  ['bg-light-danger', 'bg-unallocated'],
];

function GroupList({ product_id, productFlag, setProductFlag, productMembers, productDetails }) {
  const [groups, setGroups] = useState([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showTaskList, setShowTaskList] = useState({ show: false, group_id: '' });
  const [groupAddedSuccess, setGroupAddedSuccess] = useState(false);
  const [presetData, setPresetData] = useState({});

  const handleGroupModal = (e) => {
    e.preventDefault();
    setShowAddGroupModal(true);
  };

  const handleTaskModal = (e, data = {}) => {
    e.preventDefault();
    setPresetData(data);
    setShowAddTaskModal(true);
  };

  const handleShowTaskList = (e, group_id) => {
    e.preventDefault();
    const booleanValue = showTaskList[group_id];
    setShowTaskList({ ...showTaskList, [group_id]: !booleanValue });
  };

  const closeModal = () => {
    setShowAddGroupModal(false);
  };

  const closeTaskModal = ({ reOpen = false }) => {
    setShowAddTaskModal(false);
    if (reOpen) {
      setTimeout(() => {
        setShowAddTaskModal(true);
      }, 200);
    }
  };

  useEffect(() => {
    if (product_id) {
      getGroupsByProductId(product_id).then((response) => {
        if (response.success) {
          setGroups(response?.data?.groups);
        }
      });
    }
  }, [product_id, groupAddedSuccess, productFlag]);

  useEffect(() => {
    const groupList = {};
    groups.forEach((group) => {
      const key = group.group_id;
      groupList[key] = false;
    });
    setShowTaskList(groupList);
  }, [groups]);

  return (
    <>
      <div className="row mt-5 pt-0">
        <div className="col-md-12">
          <div className="card priority-card">
            <div className="card-header pt-7 border-bottom d-block">
              <h1>Groups</h1>
              <div className="modify-button d-flex">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button type="button" className="btn btn-sm btn-outline-primary" onClick={handleGroupModal}>
                    <i className="fa fa-download fa-plus me-1"></i>Group
                  </button>

                  <button type="button" className="btn btn-sm btn-outline-primary" onClick={handleTaskModal}>
                    <i className="fa fa-download fa-plus me-1"></i>Task
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body py-2">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                  <thead>
                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                      <th className="min-w-100px">Group ID</th>
                      <th className="min-w-175px">Title</th>
                      <th className="min-w-100px">Tasks</th>
                      <th className="min-w-100px">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="fw-semibold text-gray-600">
                    {groups.length > 0 &&
                      groups.map((group, index) => (
                        <>
                          <tr key={group.id + index} className="header-level">
                            <td>
                              <span className="text-gray-800 fs-5 fw-bold">{group?.group_id}</span>
                            </td>
                            <td>
                              <span className="text-gray-800 fs-5 fw-bold">{group?.name}</span>
                            </td>
                            <td>
                              <div className="vis-item-overflow">
                                <div className="vis-item-content" style={{ transform: 'translateX(0px)' }}>
                                  <div
                                    className={`rounded-pill ${
                                      groupColorClasses[index % groupColorClasses.length][0]
                                    } d-flex align-items-center position-relative h-30px w-100 p-2 overflow-hidden`}
                                  >
                                    <div
                                      className={`position-absolute rounded-pill d-block ${
                                        groupColorClasses[index % groupColorClasses.length][1]
                                      } start-0 top-0 h-100 z-index-1`}
                                      style={{ width: `${group?.total_tasks > 0 ? `${group?.completed_percentage}` : '0'}%` }}
                                    ></div>
                                    <div className="d-flex align-items-center position-relative z-index-2">
                                      <div className="symbol-group symbol-hover flex-nowrap me-3"></div>
                                      <div
                                        className={`fw-bold ${
                                          group?.total_tasks > 0 && group?.completed_percentage > 10
                                            ? 'text-white text-hover-dark'
                                            : 'text-dark text-hover-dark'
                                        }`}
                                      >
                                        {group?.total_tasks}
                                      </div>
                                    </div>
                                    <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2">
                                      {group?.total_tasks > 0 ? `${group?.completed_percentage}%` : 'No Tasks'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div>
                                <span
                                  role="button"
                                  className="mx-3 expand bg-light-primary text-primary symbol-label rounded p-1 px-2 symbol-circle"
                                  onClick={(e) => handleShowTaskList(e, group.group_id)}
                                >
                                  <i className={`fa fa-caret-${showTaskList[group.group_id] ? 'up' : 'down'} text-primary`}></i>
                                </span>
                                <span role="button" onClick={(e) => handleTaskModal(e, { group: { value: group.id, label: group?.name } })}>
                                  <i className="fa fa-plus text-primary"></i>
                                </span>
                              </div>
                            </td>
                          </tr>
                          {showTaskList[group.group_id] ? <TasksList key={`group.id.${index}`} taskList={group.Charts} /> : null}
                        </>
                      ))}
                    {/* <tr className="sub-level">
                      <td colspan="6" className="p-0">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                          <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-100px">Task ID</th>
                              <th className="min-w-175px">Title</th>
                              <th className="min-w-100px">Group</th>
                              <th className="min-w-70px">Start Date</th>
                              <th className="min-w-100px">End Date</th>
                              <th className="min-w-100px">Assignees</th>
                              <th className="min-w-100px">Status</th>
                              <th className="min-w-100px">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13603
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Lorem ipsum</span>
                              </td>
                              <td>Group 1</td>
                              <td>19 Feb 24</td>
                              <td>21 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-info">
                                      <span className="fs-7 text-info">Y</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-danger">
                                      <span className="fs-7 text-danger">E</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-warning fw-bold">In Progress</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13602
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Repudiandae nam doloribus recusandae</span>
                              </td>
                              <td>Group 2</td>
                              <td>15 Feb 24</td>
                              <td>25 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-info">
                                      <span className="fs-7 text-info">A</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-warning">
                                      <span className="fs-7 text-warning">G</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-primary">
                                      <span className="fs-7 text-primary">O</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-info fw-bold">To Do</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="header-level">
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">13602</span>
                      </td>
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">PCB Board</span>
                      </td>
                      <td>
                        <div className="vis-item-overflow">
                          <div className="vis-item-content" style={{ transform: 'translateX(0px)' }}>
                            <div className="rounded-pill bg-light-info d-flex align-items-center position-relative h-30px w-100 p-2 overflow-hidden">
                              <div
                                className="position-absolute rounded-pill d-block bg-light-info start-0 top-0 h-100 z-index-1"
                                style={{ width: '0%' }}
                              ></div>
                              <div className="d-flex align-items-center position-relative z-index-2">
                                <div className="symbol-group symbol-hover flex-nowrap me-3"></div>
                                <div className="fw-bold text-dark text-hover-dark">4</div>
                              </div>
                              <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2">
                                0%
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        {/* <a href="javascript:void()" className="ms-3 expand bg-light-primary text-primary symbol-label rounded p-1 px-2 symbol-circle">
                          <i className="fa fa-caret-down text-primary"></i>
                        </a> */}
                    {/* <a href="javascript:void()" className="ms-3" data-bs-toggle="modal" data-bs-target="#addtask">
                          <i className="fa fa-plus text-primary"></i>
                        </a>
                      </td>
                    </tr> */}
                    {/* <tr className="sub-level">
                      <td colspan="6" className="p-0">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                          <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-100px">Task ID</th>
                              <th className="min-w-175px">Title</th>
                              <th className="min-w-100px">Group</th>
                              <th className="min-w-70px">Start Date</th>
                              <th className="min-w-100px">End Date</th>
                              <th className="min-w-100px">Assignees</th>
                              <th className="min-w-100px">Status</th>
                              <th className="min-w-100px">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13603
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Lorem ipsum</span>
                              </td>
                              <td>Group 1</td>
                              <td>19 Feb 24</td>
                              <td>21 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-info">
                                      <span className="fs-7 text-info">Y</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-danger">
                                      <span className="fs-7 text-danger">E</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-warning fw-bold">In Progress</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13604
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Cumque itaque minus</span>
                              </td>
                              <td>Group 6</td>
                              <td>20 Feb 24</td>
                              <td>28 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-info">
                                      <span className="fs-7 text-info">H</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-danger">
                                      <span className="fs-7 text-danger">E</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-success fw-bold">Completed</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="header-level">
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">13601</span>
                      </td>
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">Body Design </span>
                      </td>
                      <td>
                        <div className="vis-item-overflow">
                          <div className="vis-item-content" style={{ transform: 'translateX(0px)' }}>
                            <div className="rounded-pill bg-light-success d-flex align-items-center position-relative h-30px w-100 p-2 overflow-hidden">
                              <div
                                className="position-absolute rounded-pill d-block bg-success start-0 top-0 h-100 z-index-1"
                                style={{ width: '80%' }}
                              ></div>
                              <div className="d-flex align-items-center position-relative z-index-2">
                                <div className="symbol-group symbol-hover flex-nowrap me-3"></div>
                                <a href="#" className="fw-bold text-white text-hover-dark">
                                  4
                                </a>
                              </div>
                              <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2">
                                80%
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <a href="javascript:void()" className="ms-3 expand bg-light-primary text-primary symbol-label rounded p-1 px-2 symbol-circle">
                          <i className="fa fa-caret-down text-primary"></i>
                        </a>
                        <a href="javascript:void()" className="ms-3" data-bs-toggle="modal" data-bs-target="#addtask">
                          <i className="fa fa-plus text-primary"></i>
                        </a>
                      </td>
                    </tr>
                    <tr className="sub-level">
                      <td colspan="6" className="p-0">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                          <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-100px">Task ID</th>
                              <th className="min-w-175px">Title</th>
                              <th className="min-w-100px">Group</th>
                              <th className="min-w-70px">Start Date</th>
                              <th className="min-w-100px">End Date</th>
                              <th className="min-w-100px">Assignees</th>
                              <th className="min-w-100px">Status</th>
                              <th className="min-w-100px">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13601
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Lorem ipsum dolor sit amet consectetur adipisicing elit. </span>
                              </td>
                              <td>Group 3</td>
                              <td>20 Feb 24</td>
                              <td>24 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-primary">
                                      <span className="fs-7 text-primary">R</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-danger">
                                      <span className="fs-7 text-danger">T</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-warning fw-bold">In Progress</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13604
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Cumque itaque minus</span>
                              </td>
                              <td>Group 6</td>
                              <td>20 Feb 24</td>
                              <td>28 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-info">
                                      <span className="fs-7 text-info">H</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-danger">
                                      <span className="fs-7 text-danger">E</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-success fw-bold">Completed</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="header-level">
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">13604</span>
                      </td>
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">Wiring</span>
                      </td>
                      <td>
                        <div className="vis-item-overflow">
                          <div className="vis-item-content" style={{ transform: 'translateX(0px)' }}>
                            <div className="rounded-pill bg-light-danger d-flex align-items-center position-relative h-30px w-100 p-2 overflow-hidden">
                              <div
                                className="position-absolute rounded-pill d-block bg-unallocated start-0 top-0 h-100 z-index-1"
                                style={{ width: '0%' }}
                              ></div>
                              <div className="d-flex align-items-center position-relative z-index-2">
                                <div className="symbol-group symbol-hover flex-nowrap me-3"></div>
                                <a href="#" className="fw-bold text-dark text-hover-dark">
                                  4
                                </a>
                              </div>
                              <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2">
                                0%
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <a href="javascript:void()" className="ms-3 expand bg-light-primary text-primary symbol-label rounded p-1 px-2 symbol-circle">
                          <i className="fa fa-caret-down text-primary"></i>
                        </a>
                        <a href="javascript:void()" className="ms-3" data-bs-toggle="modal" data-bs-target="#addtask">
                          <i className="fa fa-plus text-primary"></i>
                        </a>
                      </td>
                    </tr>
                    <tr className="sub-level">
                      <td colspan="6" className="p-0">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                          <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-100px">Task ID</th>
                              <th className="min-w-175px">Title</th>
                              <th className="min-w-100px">Group</th>
                              <th className="min-w-70px">Start Date</th>
                              <th className="min-w-100px">End Date</th>
                              <th className="min-w-100px">Assignees</th>
                              <th className="min-w-100px">Status</th>
                              <th className="min-w-100px">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13603
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Lorem ipsum</span>
                              </td>
                              <td>Group 1</td>
                              <td>19 Feb 24</td>
                              <td>21 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-info">
                                      <span className="fs-7 text-info">Y</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-danger">
                                      <span className="fs-7 text-danger">E</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-warning fw-bold">In Progress</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="header-level">
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">13605</span>
                      </td>
                      <td>
                        <span className="text-gray-800 fs-5 fw-bold">Blade Design</span>
                      </td>
                      <td>
                        <div className="vis-item-overflow">
                          <div className="vis-item-content" style={{ transform: 'translateX(0px)' }}>
                            <div className="rounded-pill bs-yellow-light d-flex align-items-center position-relative h-30px w-100 p-2 overflow-hidden">
                              <div
                                className="position-absolute rounded-pill d-block bg-yellow start-0 top-0 h-100 z-index-1"
                                style={{ width: '50%' }}
                              ></div>
                              <div className="d-flex align-items-center position-relative z-index-2">
                                <div className="symbol-group symbol-hover flex-nowrap me-3"></div>
                                <a href="#" className="fw-bold text-white text-hover-dark">
                                  1
                                </a>
                              </div>
                              <div className="d-flex flex-center bg-body rounded-pill fs-7 fw-bolder ms-auto h-100 px-3 position-relative z-index-2">
                                50%
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <a href="javascript:void()" className="ms-3 expand bg-light-primary text-primary symbol-label rounded p-1 px-2 symbol-circle">
                          <i className="fa fa-caret-down text-primary"></i>
                        </a>
                        <a href="javascript:void()" className="ms-3" data-bs-toggle="modal" data-bs-target="#addtask">
                          <i className="fa fa-plus text-primary"></i>
                        </a>
                      </td>
                    </tr>
                    <tr className="sub-level">
                      <td colspan="6" className="p-0">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                          <thead>
                            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                              <th className="min-w-100px">Task ID</th>
                              <th className="min-w-175px">Title</th>
                              <th className="min-w-100px">Group</th>
                              <th className="min-w-70px">Start Date</th>
                              <th className="min-w-100px">End Date</th>
                              <th className="min-w-100px">Assignees</th>
                              <th className="min-w-100px">Status</th>
                              <th className="min-w-100px">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold text-gray-600">
                            <tr>
                              <td>
                                <a href="task_details.html" className="text-gray-800 text-hover-primary fw-bold">
                                  13602
                                </a>
                              </td>
                              <td>
                                <span className="text-gray-800 fs-5 fw-bold">Repudiandae nam doloribus recusandae</span>
                              </td>
                              <td>Group 2</td>
                              <td>15 Feb 24</td>
                              <td>25 Feb 24</td>
                              <td>
                                <div className="symbol-group symbol-hover mb-1">
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-info">
                                      <span className="fs-7 text-info">A</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-warning">
                                      <span className="fs-7 text-warning">G</span>
                                    </div>
                                  </div>
                                  <div className="symbol symbol-circle symbol-25px">
                                    <div className="symbol-label bg-light-primary">
                                      <span className="fs-7 text-primary">O</span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="badge badge-light-info fw-bold">To Do</div>
                              </td>
                              <td>
                                <a href="task_details.html">
                                  <i className="fa fa-eye "></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAddGroupModal && (
        <AddGroupModal
          show={showAddGroupModal}
          onHide={closeModal}
          productId={product_id}
          groupAddedSuccess={groupAddedSuccess}
          setGroupAddedSuccess={setGroupAddedSuccess}
        />
      )}
      {showAddTaskModal && (
        <AddTaskModal
          show={showAddTaskModal}
          onHide={closeTaskModal}
          productId={product_id}
          success={productFlag}
          setSuccess={setProductFlag}
          productMembers={productMembers}
          presetData={presetData}
          productDetails={productDetails}
        />
      )}
    </>
  );
}

export default GroupList;
