import { axios } from "../../utils/axios";
const baseUrl = process.env.REACT_APP_API_HOST;

export const addProduct = async (data) => {
  let jsonData;
  try {
    let res = await axios.post(`${baseUrl}/products`, data);
    jsonData = res;
  } catch (e) {
    if (e?.response) {
      jsonData = e.response.data;
    } else {
      jsonData = {};
    }
  }
  return jsonData;
};

export const getProductCardsDetails = async () => {
  try {
    return await fetch(`${baseUrl}/products`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log(error);
  }
};

export const getProductById = async (product_id) => {
  try {
    return await fetch(`${baseUrl}/products/${product_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log(error);
  }
};

export const getGroupsByProductId = async (product_id) => {
  try {
    return await fetch(`${baseUrl}/products/${product_id}/groups`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log(error);
  }
};

export const addGroupForProduct = async (data) => {
  let jsonData;
  try {
    let res = await axios.post(`${baseUrl}/groups`, data);
    jsonData = res;
  } catch (e) {
    if (e?.response) {
      jsonData = e.response.data;
    } else {
      jsonData = {};
    }
  }
  return jsonData;
};

export const getMasterData = () => {
  try {
    return fetch(`${baseUrl}/hn-master-data`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log(error);
  }
};

export const getGroupsListByProductId = async (id) => {
  try {
    return await fetch(`${baseUrl}/products/${id}/groups-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => res.json());
  } catch (error) {
    console.log(error);
  }
};

export const productListFilter = async (page = 1, data = {}) => {
  let jsonData;
  try {
    let res = await axios.post(`${baseUrl}/products/filter?page=${page}&size=5`, data);
    jsonData = res;
  } catch (e) {
    if (e?.response) {
      jsonData = e.response.data;
    } else {
      jsonData = {};
    }
  }
  return jsonData;
};

export const editProduct = async (product_id, data) => {
  let jsonData;
  try {
    let res = await axios.put(`${baseUrl}/products/${product_id}`, data);
    jsonData = res;
  } catch (e) {
    if (e?.response) {
      jsonData = e.response.data;
    } else {
      jsonData = {};
    }
  }
  return jsonData;
};