import { createContext, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import TaskForm from './TaskForm';
import Chartcontext from '../../../Context/Chartcontext';
import { getTaskIdByTaskNumber } from '../../../services/chartService';
import { getTaskDetails } from '../../../services/sujata/tasks';
import { getGroupsListByProductId, getProductById } from '../../../services/sujata/products';
import TaskDetailsHeader from './TaskDetailsHeader';
import TaskComments from './TaskComments';
import TaskActivityLog from './TaskActivityLog';
import TaskLogHours from './TaskLogHours';

const milestoneMap = {
  1: 'Todo',
  2: 'In Progress',
  3: 'Completed',
};

const priorityMap = {
  1: 'Critical',
  2: 'High',
  3: 'Medium',
  4: 'Low',
};

const EnabledContext = createContext(false);

function TaskDetails() {
  const enabled = false;
  const navigateStateGlobal = useLocation();
  const [chartData, setChartData] = useState([]);
  const [reRenderChart, setReRenderChart] = useState(false);
  const { unique_task_no, task_id } = useParams();
  const [id, setId] = useState(null);
  const Chartform = useContext(Chartcontext);

  useEffect(() => {
    if (id) {
      fetchRecord(id);
      // fetchActivityLogs(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reRenderChart]);

  useEffect(() => {
    if (!task_id) {
      getTaskIdByTaskNumber(unique_task_no)
        .then((response) => {
          setId(response?.data?.Id);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setId(task_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task_id, unique_task_no, Chartform?.flagChange, navigateStateGlobal, reRenderChart]);

  const fetchRecord = async () => {
    try {
      if (!id) return;
      let response = await getTaskDetails(id);
      if (response.success) {
        const product = await getProductById(response.data.ProductId);
        if (!product.success) {
          throw new Error("Couldn't fetch Product");
        }
        const groups = await getGroupsListByProductId(response.data.ProductId);
        if (!groups.success) {
          throw new Error("Couldn't fetch Groups");
        }
        const assignees = [];
        setChartData(response.data);
        response.data.assignee_id.forEach((assignee) => {
          assignees.push({
            value: assignee.id,
            label: `${assignee.first_name} ${assignee.last_name}`,
            image: assignee.image_url,
          });
        });
        Chartform.setAssignee(assignees);
        Chartform.setMilestone({
          value: response.data.MilestoneId,
          label: milestoneMap[response.data.MilestoneId],
        });
        Chartform.setEpic({
          value: response.data.group_id,
          label: response.data.group_name,
        });
        Chartform.setPriority({
          value: response.data.priority_id,
          label: priorityMap[response.data.priority_id],
        });

        const chartResponse = response.data;
        if (!chartResponse.start_date || !chartResponse.end_date) {
          Chartform.setInputval({
            start_date: chartResponse.start_date,
            end_date: chartResponse.end_date,
            task_no: chartResponse?.unique_task_no,
            description: chartResponse?.description,
            name: chartResponse?.name,
          });
          setChartData((previousData) => ({
            ...previousData,
            start_date: chartResponse.start_date,
            end_date: chartResponse.end_date,
            product: {
              start_date: product.data.start_date,
              end_date: product.data.end_date,
              members: product.data.productMember,
              type: product.data.type,
              duration: product.data.duration,
              name: product.data.name,
            },
            groups: groups.data,
          }));
        } else {
          const startDate = chartResponse.start_date.split('-');
          const endDate = chartResponse.end_date.split('-');
          const [sy, sm, sd] = startDate;
          const [ey, em, ed] = endDate;

          Chartform.setInputval({
            start_date: `${sd}-${sm}-${sy}`,
            task_no: chartResponse?.unique_task_no,
            description: chartResponse?.description,
            end_date: `${ed}-${em}-${ey}`,
            name: chartResponse?.name,
          });
          setChartData((previousData) => ({
            ...previousData,
            start_date: `${sd}-${sm}-${sy}`,
            end_date: `${ed}-${em}-${ey}`,
            product: {
              start_date: product.data.start_date,
              end_date: product.data.end_date,
              members: product.data.productMember,
              type: product.data.type,
              duration: product.data.duration,
              name: product.data.name,
            },
            groups: groups.data,
          }));
        }
      } else {
        window.toastr.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <EnabledContext.Provider value={enabled}>
      <div className="content d-flex flex-column flex-column-fluid fs-6" id="kt_content">
        <div className="container-xxl" id="kt_content_container">
          <div className="d-flex justify-content-between progress-button mt-5">
            <div>
              <h2 className="pb-0 mb-0 ms-0 pt-0">Task Details</h2>
              <ul className="breadcrumb fw-semibold fs-base mb-1">
                <li className="breadcrumb-item text-muted">
                  <Link to="/" className="text-muted text-hover-primary">
                    Home{' '}
                  </Link>
                </li>
                <li className="breadcrumb-item text-muted">
                  <Link to="/tasks" className="text-muted text-hover-primary">
                    Tasks{' '}
                  </Link>
                </li>

                <li className="breadcrumb-item text-gray-900">Task details </li>
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <TaskDetailsHeader chartData={chartData} />
              <TaskForm id={id} clientData={chartData} getTaskDetails={getTaskDetails} setReRenderChart={setReRenderChart} />
            </div>

            {/* //comments, loghours */}
            <div className="col-xl-4 col-lg-12 col-md-12">
              <div className="row">
                {id && <TaskComments id={id} data={chartData} />}
                {id && <TaskActivityLog id={id} enabled={enabled} reRenderChart={reRenderChart} />}
                {id && <TaskLogHours id={id} enabled={enabled} taskDetails={chartData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </EnabledContext.Provider>
  );
}

export default TaskDetails;
