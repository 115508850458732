import React from 'react';
// import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* <div className="container-fluid d-flex flex-column flex-md-row flex-stack">
        <p className="text-gray-900 order-2 order-md-1">
          © 2024,
          <Link to="https://minditsystems.com/" target="_blank">
            Mind IT Systems
          </Link>
          All Rights Reserved.
        </p>
        <div className="text-dark order-2 order-md-1">
          <Link to="https://minditsystems.com/" target="_blank" className="text-muted text-hover-primary fw-semibold me-2 fs-6 powered-image">
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/media/logos/logo-MindIT.png`} alt="Mind IT Systems" width="100px" />
          </Link>
        </div>
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item">
            <div role="button" className="menu-link px-2">
              About
            </div>
          </li>
          <li className="menu-item">
            <div role="button" className="menu-link px-2">
              FAQ
            </div>
          </li>
          <li className="menu-item">
            <div role="button" className="menu-link px-2">
              Privacy Policy
            </div>
          </li>
        </ul>
      </div> */}
    </div>
  );
};
export default Footer;
