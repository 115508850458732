import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import AppContext from '../../../Context/AppContext';
import { addTask } from '../../../services/sujata/tasks';
import { getGroupsListByProductId, productListFilter } from '../../../services/sujata/products';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

function AddTaskModal({ show, onHide, productId = '', success, setSuccess, productMembers = [], presetData = {}, productDetails }) {
  const { masterData } = useContext(AppContext);
  const [groupList, setGroupList] = useState([]);
  const [productAssignees, setProductAssignees] = useState(productMembers ?? []);
  const [selectedProductOnAddTask, setSelectedProductOnAddTask] = useState(productId ?? '');

  useEffect(() => {
    if (productId !== '') {
      getGroupsListByProductId(productId).then((response) => {
        if (response.success) {
          setGroupList(response?.data);
        }
      });
    } else if (selectedProductOnAddTask !== '') {
      getGroupsListByProductId(selectedProductOnAddTask).then((response) => {
        if (response.success) {
          setGroupList(response?.data);
        }
      });
      productListFilter(1, { product_id: selectedProductOnAddTask })
        .then((response) => {
          if (response?.success) {
            const fetchedAssignees = [];
            if (Object.keys(response?.data).length > 0) {
              Object.keys(response?.data['0'].productMembers).forEach((key) => {
                fetchedAssignees.push(response?.data['0'].productMembers[key]);
              });
              setProductAssignees(fetchedAssignees);
            }
          }
        })
        .catch((error) => {
          window.toastr.error('Could not fetch product members');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductOnAddTask]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: presetData?.title ?? '',
      priority: presetData?.priority ?? '',
      group: presetData?.group ?? '',
      assignees: presetData?.assignees ?? '',
      start_date: presetData?.start_date ?? '',
      end_date: presetData?.end_date ?? '',
      description: presetData?.description ?? '',
      product_id: productId,
    },
  });

  const watchStartDate = watch('start_date', '');

  const renderAllUsers = () => {
    return productAssignees?.map((member) => {
      return {
        label: `${member.first_name ?? ''} ${member.last_name ?? ''}`,
        value: member?.id,
      };
    });
  };

  const renderAllPriorities = () => {
    return masterData?.priorities?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const renderAllGroups = () => {
    return groupList.map((data) => {
      return {
        label: `${data?.name} (${data?.group_id})`,
        value: data?.id,
      };
    });
  };

  const renderAllProducts = () => {
    return masterData?.product_names?.map((data) => {
      return {
        label: `${data?.name}`,
        value: data?.id,
      };
    });
  };

  const getSelectedProductDates = () => {
    let product;
    masterData?.product_names?.forEach((productObj) => {
      if (selectedProductOnAddTask === productObj?.id) {
        product = {
          start_date: moment(productObj?.start_date).format('DD-MM-YYYY'),
          end_date: moment(productObj?.end_date).format('DD-MM-YYYY')
        };
      }
    });
    return product;
  };

  const handleProductSelection = (selectedOption) => {
    setSelectedProductOnAddTask(selectedOption?.value);
    setValue('product_id', selectedOption);
  };

  const handleFormSubmit = (data, reOpenFlag = true) => {
    const buttons = document.getElementsByClassName('addTaskButton');
    buttons.forEach((btn) => {
      btn.disabled = true;
    });
    let selectedTaskAssignees = [];
    if (Array.isArray(data?.assignees) && data?.assignees.length > 0) {
      selectedTaskAssignees = data?.assignees?.map((option) => option?.value);
    } else {
      if (Array.isArray(getValues('assignees'))) {
        selectedTaskAssignees = getValues('assignees')?.map((option) => option?.value);
      }
    }
    const saveObj = {
      title: getValues('title'),
      priority: getValues('priority')?.value,
      start_date: getValues('start_date'),
      end_date: getValues('end_date'),
      assignees: selectedTaskAssignees.length,
    };
    if (!reOpenFlag && (!saveObj?.start_date || !saveObj?.end_date || !saveObj?.title || !saveObj?.priority || !saveObj?.assignees)) {
      window.toastr.error('Please fill all required fields');
      buttons.forEach((btn) => {
        btn.disabled = false;
      });
      return;
    }

    const reqBody = {
      name: data?.title ?? getValues('title'),
      description: data?.description ?? getValues('description'),
      start_date: data?.start_date ? moment(data?.start_date?.[0]).format('DD-MM-YYYY') : moment(getValues('start_date')?.[0]).format('DD-MM-YYYY'),
      end_date: data?.end_date ? moment(data?.end_date?.[0]).format('DD-MM-YYYY') : moment(getValues('end_date')?.[0]).format('DD-MM-YYYY'),
      assignee_id: selectedTaskAssignees,
      product_id: productId === '' ? data?.product_id?.value ?? getValues('product_id')?.value : productId,
      priority_id: data?.priority?.value ?? getValues('priority')?.value,
      group_id: data?.group?.value ?? getValues('group')?.value,
    };

    addTask(reqBody)
      .then((data) => {
        buttons.forEach((btn) => {
          btn.disabled = false;
        });
        if (data?.success === true) {
          window.toastr.success('Task added successfully');
          onHide({ reOpen: reOpenFlag });
          setSuccess(!success);
        } else {
          if (data?.success === false) {
            window.toastr.error(data?.message ?? 'Could not add task');
          }
          if (data?.error) {
            let errors = data?.error?.map((key) => {
              return key.message;
            });
            window.toastr.error(errors ?? 'Could not add task');
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // Handle form submission here
  };

  return (
    <>
      <Modal show={show} onHide={() => onHide({ reOpen: false })} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleFormSubmit)} id="kt_modal_new_card_form">
            <div className="row">
              {productId === '' && (
                <div className="col-md-12 fv-row mb-7">
                  <label className="required fs-6 fw-semibold form-label assign-to">Product</label>
                  <Controller
                    name="product_id"
                    control={control}
                    rules={{ required: 'Product must be selected' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={renderAllProducts()}
                        onChange={(selectedOption) => {
                          handleProductSelection(selectedOption);
                        }}
                      />
                    )}
                  />
                  <small className="text-danger">{errors?.product_id && errors.product_id.message}</small>
                </div>
              )}
              <div className="col-md-6 fv-row mb-2">
                <label className="required fs-6 fw-semibold form-label  assign-to">Title</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('title', {
                    required: 'Title is required',
                    validate: (value) => {
                      if (value.trim() === '') {
                        return 'Required';
                      }
                      if (value.trim().length < 3) {
                        return "Input can't be less than 3 characters";
                      }
                      if (value.trim().length > 100) {
                        return "Input can't be more then 100 characters";
                      }
                      return true;
                    },
                  })}
                />
                <small className="text-danger">{errors?.title && errors.title.message}</small>
              </div>
              <div className="col-md-6 fv-row mb-2">
                <label className="required fs-6 fw-semibold form-label assign-to">Priority</label>
                <Controller
                  name="priority"
                  control={control}
                  rules={{ required: 'Priority must be set' }}
                  render={({ field }) => <Select {...field} options={renderAllPriorities()} />}
                />
                <small className="text-danger">{errors?.priority && errors.priority.message}</small>
              </div>
              <div className="col-md-12 fv-row mt-5">
                <label className=" fs-6 fw-semibold form-label mb-2 assign-to">Group</label>
                <Controller name="group" control={control} render={({ field }) => <Select {...field} options={renderAllGroups()} />} />
              </div>
              <div className="col-md-12 mt-5 fv-row mb-2">
                <label className="required fs-6 fw-semibold form-label assign-to">Assignees</label>
                <Controller
                  name="assignees"
                  control={control}
                  rules={{ required: 'Task must be assigned' }}
                  render={({ field }) => <Select {...field} isMulti options={renderAllUsers()} />}
                />
                <small className="text-danger">{errors?.assignees && errors.assignees.message}</small>
              </div>
              <div className="col-md-6 fv-row mt-5 mb-2">
                <label className="required fs-6 fw-semibold form-label assign-to">Start date</label>
                <Controller
                  name="start_date"
                  control={control}
                  render={({ field }) => (
                    <Flatpickr
                      {...field}
                      options={{
                        dateFormat: 'd/m/Y',
                        minDate: productDetails?.start_date ?? getSelectedProductDates()?.['start_date'] ,
                        maxDate: productDetails?.end_date ?? getSelectedProductDates()?.['end_date'],
                      }}
                      disabled={selectedProductOnAddTask === ''}
                      className="form-control"
                    />
                  )}
                  rules={{ required: 'Required' }}
                />
                <small className="text-danger">{errors?.start_date && errors.start_date.message}</small>
              </div>

              <div className="col-md-6 fv-row mt-5 mb-2">
                <label className="required fs-6 fw-semibold form-label assign-to">End date</label>
                <Controller
                  name="end_date"
                  control={control}
                  render={({ field }) => (
                    <Flatpickr
                      {...field}
                      options={{
                        dateFormat: 'd/m/Y',
                        minDate: watchStartDate ? new Date(watchStartDate) : null,
                        maxDate: productDetails?.end_date ?? getSelectedProductDates()?.['end_date'],
                      }}
                      disabled={watchStartDate === ''}
                      className="form-control"
                    />
                  )}
                  rules={{ required: 'Required' }}
                />
                <small className="text-danger">{errors?.end_date && errors.end_date.message}</small>
              </div>

              <div className="col-md-12 mt-5 fv-row">
                <label className=" fs-6 fw-semibold form-label mb-2 assign-to">Description</label>
                <textarea
                  cols="30"
                  rows="3"
                  className="form-control"
                  {...register('description', {
                    maxLength: { value: 500, message: 'Maximum length should be 500 characters' },
                  })}
                ></textarea>
                <small className="text-danger">{errors?.description && errors.description.message}</small>
              </div>
            </div>

            <div className="text-left pt-5 mt-5">
              <button type="submit" className="addTaskButton btn btn-sm btn-secondary">
                <span className="indicator-label">Save & Next</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
              <button
                type="button"
                id="kt_modal_new_card_cancel"
                className="addTaskButton btn btn-sm btn-outline-secondary ms-3"
                onClick={(data) => handleSubmit(handleFormSubmit(data, false))}
              >
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTaskModal;
