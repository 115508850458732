import { useState, useEffect } from "react";
import EstimationHoursModal from "./EstimationHoursModal";
import { getPlannedVsActual } from "../../../services/managerWorkListService";

const ProjectPlan = ({ worklistId, masterData }) => {
  const [showModalBox, setShowModalBox] = useState(false);
  const [hoursTagDataForProject, setHoursTagDataForProject] = useState();
  const [rerenderLogData, setReRenderLogData] = useState();
  
  useEffect(() => {
    fetchPlannedVsActual();
  // eslint-disable-next-line
  }, [rerenderLogData]);

  const fetchPlannedVsActual = async () => {
    const hoursTagData = await getPlannedVsActual(worklistId);
    setHoursTagDataForProject(hoursTagData?.data);
  };
  const handleRerenderData = (response) => {
    setReRenderLogData(response);
  };

  return (
    <>
      <div className="col-12 mt-6 p-0">
        <div className="card card-flush h-lg-100">
          <div className="card-header py-4 minimize">
            <p className="card-title align-items-start flex-column">
              <span className="fw-bold h4">Planned vs. Actual Effort</span>
            </p>
            <div className="card-toolbar mt-0">
              <button className="btn btn-icon btn-sm btn-light-primary justify-content-center minimize">
                <i className="fas fa-plus" />
              </button>
            </div>
          </div>
          <div className="card-body py-2 collapse">
            <div className="table-responsive" style={{ maxHeight: "250px", overflowY: "auto" }}>
              <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_todo_list">
                <thead>
                  <tr className="text-start text-dark fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-80px ps-2">Tag</th>
                    <th className="min-w-80px">Planned Hours</th>
                    <th className="min-w-80px">Actual Hours</th>
                    <th className="min-w-80px">Elapsed %</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold">
                  {hoursTagDataForProject?.map(
                    (row, index) =>
                      row?.tagId != null && (
                        <tr key={index}>
                          <td>{row?.tagName}</td>
                          <td>{row?.estimation_hours}</td>
                          <td>{row?.totalHours}</td>
                          <td>{((parseFloat(row?.totalHours??0) / parseFloat(row?.estimation_hours??0)) * 100 ).toFixed(2) + "%"}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>{" "}
            </div>
            <div className="row p-2 ">
              <div className="form-group mt-2">
                <button
                  type="button"
                  data-repeater-create=""
                  className="btn btn-sm btn-primary fw-bold"
                  onClick={(e) => {
                    setShowModalBox(true);
                  }}
                >
                  <i className="fas fa-plus"></i>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EstimationHoursModal showModalBox={showModalBox} setShowModalBox={setShowModalBox} masterData={masterData} worklistId={worklistId} handleRerenderdata={handleRerenderData} />
    </>
  );
};
export default ProjectPlan;
