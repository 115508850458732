import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => {
  if (label.trim() !== '') {
    return {
      label,
      value: label,
    };
  }
};

function CreatableInput({ placeholderString = 'Type something and press Enter...', value, setValue, options }) {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => {
          return [...prev, createOption(inputValue.trim())];
        });
        setInputValue('');
        event.preventDefault();
        break;
      default:
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue !== '' && newValue.trim() !== '' ? newValue : '');
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable={true}
        isMulti={true}
        onChange={(newValue) => handleChange(newValue)}
        onInputChange={(newValue) => handleInputChange(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={placeholderString}
        options={options}
        value={value}
      />
    </>
  );
}

export default CreatableInput;
