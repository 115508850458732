import React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { addGroupForProduct } from '../../../../services/sujata/products';

function AddGroupModal({ show, onHide, productId, groupAddedSuccess, setGroupAddedSuccess }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      title: '',
    },
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    const button = document.getElementById('kt_modal_new_card_submit');
    button.disabled = true;
    const body = { name: data.title, worklist_id: productId };
    const response = await addGroupForProduct(body);
    button.disabled = false;
    if (response.success) {
      window.toastr.success('Group added successfully');
      setGroupAddedSuccess(!groupAddedSuccess);
      onHide();
    } else {
      window.toastr.error('Failed to add group');
    }
  });

  return (
    <>
      <Modal show={show} onHide={onHide} dialogClassName="mw-400px" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Add Group</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="kt_modal_new_card_form" className="form" onSubmit={handleFormSubmit}>
            <div className="row">
              <div className="col-md-12 fv-row">
                <label className="required fs-6 fw-semibold form-label mb-2 assign-to">Title</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('title', {
                    required: 'Title cannot be empty',
                    validate: (value) => {
                      if (value.trim() === '') {
                        return 'Required';
                      }
                      if (value.trim().length < 3) {
                        return "Input can't be less than 3 characters";
                      }
                      if (value.trim().length > 100) {
                        return "Input can't be more then 100 characters";
                      }
                      return true;
                    },
                  })}
                />
                <small className="text-danger">{errors?.title && errors.title.message}</small>
              </div>
            </div>
            <div className="text-left pt-5 mt-5">
              <button type="submit" id="kt_modal_new_card_submit" className="btn btn-sm btn-secondary">
                <span className="indicator-label">Save</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
              <button type="reset" id="kt_modal_new_card_cancel" className="btn btn-sm btn-outline-secondary ms-3" onClick={onHide}>
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* <div className="modal fade" id="addgroup" tabindex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-400px">
          <div className="modal-content">
            <div className="modal-header pb-2">
              <h2>Add Group</h2>
              <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                <i className="fas fa-times"></i>
              </div>
            </div>
            <div className="modal-body  mb-4">
              <form id="kt_modal_new_card_form" className="form" action="#">
                <div className="row">
                  <div className="col-md-12 fv-row">
                    <label className="required fs-6 fw-semibold form-label mb-2 assign-to">Title</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>

                <div className="text-left pt-5 mt-5">
                  <button type="submit" id="kt_modal_new_card_submit" className="btn btn-sm btn-secondary" data-bs-dismiss="modal">
                    <span className="indicator-label">Save</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                  <button type="reset" id="kt_modal_new_card_cancel" className="btn btn-sm btn-outline-secondary ms-3" data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default AddGroupModal;
