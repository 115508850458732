import React from 'react';
import Info from '../TemplateWizard/TemplateWizardFormComponents/Info';
import Groups from '../TemplateWizard/TemplateWizardFormComponents/Groups';
import Tasks from '../TemplateWizard/TemplateWizardFormComponents/Tasks';
import Done from '../TemplateWizard/TemplateWizardFormComponents/Done';

function WizardForm({ activeStep = 'info', setActiveStep, hideTemplateModal, setShowAddProductModal, success, setSuccess, wizardType }) {
  const renderActiveForm = () => {
    switch (activeStep) {
      case 'info':
        return <Info prevStep={{ value: '', index: null }} nextStep={{ value: 'groups', index: 1 }} setActiveStep={setActiveStep} />;
      case 'groups':
        return <Groups prevStep={{ value: 'info', index: 0 }} nextStep={{ value: 'tasks', index: 2 }} setActiveStep={setActiveStep} />;
      case 'tasks':
        return (
          <Tasks
            prevStep={{ value: 'groups', index: 1 }}
            nextStep={{ value: 'done', index: 3 }}
            setActiveStep={setActiveStep}
            success={success}
            setSuccess={setSuccess}
            wizardType={wizardType}
          />
        );
      case 'done':
        return (
          <Done
            prevStep={{ value: '', index: null }}
            nextStep={{ value: '', index: null }}
            setActiveStep={setActiveStep}
            setShowAddProductModal={setShowAddProductModal}
            hideTemplateModal={hideTemplateModal}
            wizardType={wizardType}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderActiveForm()}</>;
}

export default WizardForm;
