import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import AppContext from '../../../../../Context/AppContext';

function Info({ prevStep, nextStep, setActiveStep }) {
  const handleNextClick = () => {
    setActiveStep(nextStep);
  };

  const handlePreviousClick = (e) => {
    e.preventDefault();
    setActiveStep(prevStep);
  };

  const { templateData, setTemplateData } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: templateData.name ?? '',
      description: templateData.description ?? '',
    },
  });

  const handleFormSubmit = handleSubmit((data) => {
    setTemplateData((templateData) => ({ ...templateData, name: data.name, description: data.description }));
    handleNextClick();
  });

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="current">
        <div className="w-100">
          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span className="required">Template Name</span>
            </label>
            <input
              type="text"
              className="form-control"
              {...register('name', {
                required: 'Template name cannot be empty',
                validate: (value) => {
                  if (value.trim() === '') {
                    return 'Required';
                  }
                  if (value.trim().length < 3) {
                    return "Input can't be less than 3 characters";
                  }
                  if (value.trim().length > 100) {
                    return "Input can't be more then 100 characters";
                  }
                  return true;
                },
              })}
            />
            {errors.name && <span className="text-danger">{errors.name.message}</span>}
          </div>
          <div className="fv-row mb-10">
            <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
              <span>Description</span>
            </label>
            <textarea
              className="form-control"
              aria-label="With textarea"
              {...register('description', {
                maxLength: { value: 500, message: 'Maximum length should be 500 characters' },
              })}
            ></textarea>
            {errors.description && <span className="text-danger">{errors.description.message}</span>}
          </div>
        </div>
      </div>

      <div className="d-flex flex-stack pt-10">
        <div className="me-2">
          <button type="button" className="btn btn-lg btn-light-primary me-3" disabled={!prevStep.value} onClick={handlePreviousClick}>
            <i className="fa-solid fa-arrow-left text-primary fs-6"></i> Back
          </button>
        </div>
        <div>
          <button type="submit" className="btn btn-lg btn-primary" disabled={!nextStep.value}>
            Continue <i className="fa-solid fa-arrow-right fs-6 ms-2"></i>
          </button>
        </div>
      </div>
    </form>
  );
}

export default Info;
