import { useContext, useEffect, useState } from 'react';
import Chartcontext from '../../../Context/Chartcontext';
import { useConfirmTabClose } from '../../../hooks/useConfirmTabClose';
import TaskFormValidator from './TaskFormValidator';
import TaskInfo from './TaskInfo';
import { updateTask } from '../../../services/sujata/tasks';
import AppContext from '../../../Context/AppContext';

function TaskForm({ id, clientData, setReRenderChart }) {
  const data = useContext(Chartcontext);
  const { masterData } = useContext(AppContext);
  const [isTouched, setIsTouched] = useState(false);
  const [assignees, setAssignees] = useState([]);
  useConfirmTabClose(isTouched);
  const { milestone } = useContext(Chartcontext);
  //   const [dataSuccessfullyAdded, setDataSuccessfullyAdded] = useState(false);
  // const { errorsForChat, setErrorsForChat, errorsFromAuditorToChart } = useContext(CommentContext);
  useEffect(() => {
    setIsTouched(true);
  }, []);

  useEffect(() => {
    setAssignees(clientData.assignee);
  }, [clientData.assignee]);

  const submitChart = async (e) => {
    e.preventDefault();

    const assignedUsers = assignees?.map((assignee) => {
        return assignee.value;
    });
    
    if (assignedUsers?.length === 0) {
      window.toastr.error("Assignee field can't be empty");
      return;
    }
    const { start_date, end_date, name } = data?.inpval;
    if (!start_date || !end_date || !data.priority?.value || !milestone?.value || !name || name.trim() === '') {
      window.toastr.error("Please fill the required inputs properly");
      return;
    }

    let obj = data.inpval;
    obj = {
      ...obj,
      name: data?.inpval?.name?.trim(),
      milestone_id: milestone?.value,
      assignee_id: assignedUsers,
      group_id: data?.epic?.value,
      priority_id: data?.priority?.value,
    };
    let boolValid = await validateSchema(obj);
    if (boolValid) {
      // setErrorsForChat({});
      updateTask(id, obj)
        .then((res) => {
          if (res.success) {
            setReRenderChart((rerender) => !rerender);
            window.toastr.success(res?.message);
          }
        })
        .catch((error) => {
          window.toastr.error(error?.message);
          console.log(error);
        });
    }
  };

  const validateSchema = async (obj) => {
    const isValid = await TaskFormValidator(obj, clientData?.product);
    if (Object.keys(isValid).length === 0) {
      return true;
    } else {
      // setErrorsForChat(response);
      window.toastr.error(isValid[Object.keys(isValid)[0]]);
      return false;
    }
  };

  const getdata = (e) => {
    setIsTouched(true);
    const { name, value } = e.target;
    data.setInputval((previousValue) => ({ ...previousValue, [name]: value}));
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 8) {
      setIsTouched(true);
      const { value, name } = e.target;

      data.setInputval(() => {
        return {
          ...data.inpval,
          [name]: value,
        };
      });
    }
  };

  const handleDateTimeChange = (dates, dateStr, instance) => {
    setIsTouched(true);
    data.setInputval(() => {
      return {
        ...data.inpval,
        [instance.input.name]: instance.input.value,
      };
    });
  };

  const handleCancel = () => {
    setReRenderChart((reRender) => !reRender);
  }

  return (
    <div className="row gy-5 g-xl-10">
      <div className="col-xl-12">
        <TaskInfo
          masterData={masterData}
          clientData={clientData}
          mainData={data}
          data={Object.assign(clientData, data)}
          handleDateTimeChange={handleDateTimeChange}
          getdata={getdata}
          handleKeypress={handleKeypress}
          assignees={assignees}
          setAssignees={setAssignees}
        />

        {/* //Linked Documents */}

        <div className="d-flex justify-content-start ms-5 mb-6">
          <button onClick={(e) => submitChart(e)} type="submit" className="btn btn-sm fw-bold btn-secondary me-2">
            Save
          </button>
          <button
            type="reset"
            className="btn btn-sm btn-outline-secondary fw-bold btn-active-light-primary"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default TaskForm;
